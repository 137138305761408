import { Link } from "gatsby";
import React from "react";
import "./AgentBanner.scss";
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
import Img from "gatsby-image";
import { GetURL } from "../../utils";

const AgentBanner = (props) => {
    return (
        <React.Fragment>
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="agent-banner img-zoom">
                    {/* <img className="mb-0" src={props?.data?.Background_Image?.url} alt={props?.data?.Background_Image?.alternativeText} /> */}
                    <Img fluid={props?.data?.Background_Image?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Background_Image?.alternativeText} className="mb-0" />
                    <div className="container banner-content">
                        {/* <strong className="subtitle">Who’s behind espace</strong>
                        <h4 className="banner-title">
                        Our rapid growth is thanks to the <span> knowledge </span> and the{" "}
                        <span> quality </span>  of our agents.
                        </h4> */}
                        {(props.data) && HTMLReactParser(props.data.Content)}
                        <Link className="team-link text-uppercase" to={`/${GetURL(props?.data?.Cta_Link?.id)}`}>
                            {props?.data?.Cta_Label}
                        </Link>
                    </div>
                </div>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default AgentBanner;
