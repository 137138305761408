import React from "react";
import { Button, Form, Container, Row, Col, Modal } from "react-bootstrap";
import "./Contact.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { GetURL, removePlus } from "../utils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ContactUsForm from "../forms/contact-us-form";
import { Link, navigate } from "gatsby";
import ContactFormPage from "../forms/contact";
import BookAVideoCall from "../forms/book-a-video-call";
import contactBanner from "../../images/contact-banner.jpg";

const icons = [
    "icon-phone-red",
    "icon-whatsapp-red",
    "icon-mail-red",
    "icon-video-red",
    "icon-globe-red",
    "icon-pennib-red",
]


const Contact = (props) => {
    const { data, modules } = props;
    const [emailModal, setEmailModal] = React.useState(false);
    const [videoModal, setVideoModal] = React.useState(false);

    const handleLink = (each, icon) => {
        switch (icon) {
            case "icon-phone-red":
                window.open(`tel:${each.Description}`, "_self")
                break;
            case "icon-whatsapp-red":
                window.open(`https://api.whatsapp.com/send?phone=${removePlus(each.Description)}`, "_blank")
                break;
            case "icon-mail-red":
                setEmailModal(true);
                break;
            case "icon-video-red":
                setVideoModal(true);
                break;
            default:
                break;
        }
    }

    let processedImages = JSON.stringify({});
    if (data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = data?.imagetransforms?.Banner_Image_Transforms;
    }
    
    return (
        <div className="contact-landing contact-landing-title">
            <Container>
                <Row>
                    <Col xl={6} xs={12}>
                        <Form className="">
                            <div className="form-head">
                                <ScrollAnimation animateIn="slideInLeft" animateOnce>
                                    <h1 className="main-title">{data.SM_Title}</h1>
                                    <p className="desc">
                                        {data.Title}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <ul className="knowledge-list">
                                {modules[0].Service_Cards.sort((a, b) => a.Cta_Label - b.Cta_Label).map((each, i) => 
                                    <div className="w-100">
                                        {["icon-phone-red", "icon-whatsapp-red"].includes(icons[i]) && <React.Fragment>
                                            <a href={icons[i] === "icon-phone-red" ? `tel:${each.Description}` : `https://api.whatsapp.com/send?phone=${removePlus(each.Description)}`} className="w-100 cursor-pointer">
                                                <li className="d-flex align-items-center justify-content-between">
                                                    <i className={icons[i]}/>
                                                    <i className="icon-long-arrow"/>
                                                    <div className="info">
                                                        <strong className="d-block">{each.S_Title}</strong>
                                                        {icons[i] !== "icon-whatsapp-red" && <span className="d-block"><a className="no-link">{each.Description}</a></span>}
                                                    </div>
                                                </li>
                                            </a> 
                                        </React.Fragment>}
                                        {!["icon-phone-red", "icon-whatsapp-red"].includes(icons[i]) &&<React.Fragment>
                                            {each.Cta_Link ? 
                                                <Link to={`/${GetURL(each.Cta_Link.id)}`} className="w-100 cursor-pointer">
                                                    <li className="d-flex align-items-center justify-content-between">
                                                        <i className={icons[i]}/>
                                                        <i className="icon-long-arrow"/>
                                                        <div className="info">
                                                            <strong className="d-block">{each.S_Title}</strong>
                                                            <span className="d-block">{each.Description}</span>
                                                        </div>
                                                    </li>
                                                </Link> :
                                                <a onClick={() => handleLink(each, icons[i])} className="w-100 cursor-pointer">
                                                    <li className="d-flex align-items-center justify-content-between">
                                                        <i className={icons[i]}/>
                                                        <i className="icon-long-arrow"/>
                                                        <div className="info">
                                                            <strong className="d-block">{each.S_Title}</strong>
                                                            {icons[i] !== "icon-whatsapp-red" && <span className="d-block"><a href="javascript:void(0)">{each.Description}</a></span>}
                                                        </div>
                                                    </li>
                                                </a> 
                                            }
                                        </React.Fragment>}
                                    </div>
                                )}
                            </ul>
                        </Form>
                    </Col>
                    <Col xl={6} xs={12}>
                        <div className="knowledge-banner d-none d-xl-block">
                            {/* <img src={contactBanner} alt="image" /> */}
                            <ImageTransform
                                imagesources={data?.Banner_Image?.url}
                                renderer="srcSet" imagename="articles.Banner_Image.contact"
                                attr={{ alt: data?.Banner_Image?.alternativeText, class: '' }}
                                imagetransformresult={processedImages}
                                className="bg-img"
                                id={data?.id}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={emailModal} onHide={() => setEmailModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form appearance">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Contact Espace</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactFormPage isEnquiryType={true}/>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal size="lg" show={videoModal} onHide={() => setVideoModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Book a Video Call</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BookAVideoCall/>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};
export default Contact;
