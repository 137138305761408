import React from "react";
import { Row } from "react-bootstrap";
import "./ContentList.scss";


const ContentList = (props) => {
  return(
    <div className="content-list-wrapper"> 
        {props?.modules.filter(module => module.__typename ==="GLSTRAPI_ComponentComponentsContentWithList").map((item,index) => (
            
            <React.Fragment key={item?.id}>
                <Row className="heading-section">
                {item?.Title && <h2>{item.Title}</h2> }
                    {item?.Sm_Title && <h4 className="subtitle">{item.Sm_Title}</h4>}
                </Row>
                <Row className="list-items">
                    {item?.Service_Cards && item.Service_Cards.length > 0 && item.Service_Cards.map((list,i) => (
                        <div className="content-list-item" key={list?._id}>
                            {/* <Row>
                                <Col md={2}> */}
                                    {list?.Tile_Image && <img src={list.Tile_Image.url} alt="img-list-item" /> }
                                {/* </Col>
                                <Col md={10}> */}
                                    <div className="">
                                        {list?.S_Title && <h6>{list.S_Title}</h6>}
                                        {list?.Description && <p>{list.Description}</p>}
                                    </div>
                                {/* </Col> 
                            </Row>*/}
                        </div>
                      ))                     
                    }
                </Row>
            </React.Fragment>       
        
       ))}
    </div>
  );
};
export default ContentList;