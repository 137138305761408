import { Link } from "gatsby";
import * as React from "react";
import { Button, Form, Container, Row, Col, Accordion, Card, } from "react-bootstrap";
import ServiceCard from "../../Home/ServiceCard/ServiceCard";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import "./AreaGuides.scss";

const AreaGuides = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                areaGuides(sort: "Sort") {
                    id
                    Title
                    Url
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Sort
                    Embed_Video_Url
                }
            }
        }
    `);
    return (
        <div className="area-guide">
            <Container>
                <Row className="areaguide-slider">
                    {data.glstrapi.areaGuides?.map((each, i) => 
                        <Col xl={4} md={6} xs={12} key={i} className="px-md-0 px-auto mb-4">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="img-item">
                                    <Link className="w-100" to={`/living-in-dubai/${each.Url}/`}>
                                        <ServiceCard 
                                            cardTitle={each.Title}
                                            cardImg={each.Tile_Image?.url}
                                            imgAlt={each.Title + " - Espace"}
                                        />
                                    </Link>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>      
    );
};
export default AreaGuides;
