import { Link } from "gatsby"
import React, { useState } from "react"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap"
// Images
import videoImg from "../../../images/market-video.jpg"
import "./AboutMarket.scss"
import ScrollAnimation from "react-animate-on-scroll"
import PlayVideo from "../../Play/PlayVideo"
import HTMLReactParser from "html-react-parser"
import Img from "gatsby-image"
import { GetURL, getVideoId } from "../../utils"
import YouTube from "@u-wave/react-youtube"

const videoRef = React.createRef(null)

const AboutMarket = props => {
  const [isPlaying, setPlaying] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const playVideo = () => {
    var elem = videoRef.current
    // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
    // if(fullScreenAvailable) {
    //     if (elem.requestFullscreen) {
    //         elem.requestFullscreen();
    //     } else if (elem.webkitRequestFullscreen) { /* Safari */
    //         elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) { /* IE11 */
    //         elem.msRequestFullscreen();
    //     }
    // } else {
    //     elem.classList.add("fullscreen-video")
    // }
    elem.classList.add("fullscreen-video")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formClick",
      formType: "form-video",
      formId: "local-living-video",
      formName: "Local Living Video",
      formLabel: window.location.href,
    })
  }

  const closeVideo = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
    setPlaying(false)
  }

  const trackerVideo = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: event,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }

  const playVideoHandler = () => {
    // setPlaying(true)
    setShowVideo(true)
  }

  function fullScreenHandler() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    if (fullscreenElement == null) {
      setPlaying(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", fullScreenHandler, false)
    document.addEventListener(
      "webkitfullscreenchange",
      fullScreenHandler,
      false
    )
    document.addEventListener("MSFullscreenChange", fullScreenHandler, false)
    document.addEventListener("mozfullscreenchange", fullScreenHandler, false)
  }, [])
  return (
    <React.Fragment>
      <div className="about-market">
        <Container>
          <Row>
            <Col lg={5}>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                {props.data && HTMLReactParser(props.data.Content)}
                <Link
                  to={`/${GetURL(props?.data?.Cta_Link?.id)}`}
                  className="team-link text-uppercase"
                >
                  {props?.data?.Cta_Label}
                </Link>
              </ScrollAnimation>
            </Col>
            <Col lg={7}>
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <div className="video-banner">
                  {!showVideo ? (
                    <Img
                      fluid={
                        props?.data?.Tile_Image?.url_sharp?.childImageSharp
                          .fluid
                      }
                      alt={props?.data?.Tile_Image?.alternativeText}
                    />
                  ) : (
                    <>
                      {showVideo && (
                        <div
                          onClick={e => {
                            setShowVideo(false)
                          }}
                          className="youtube-close-button"
                        >
                          X
                        </div>
                      )}
                      {showVideo && (
                        <YouTube
                          video={getVideoId(props?.data?.Embed_Video_Url)}
                          // videoId={(`https://www.youtube.com/watch?v=Gdav7TuxTYE`)}  
                          autoplay
                          showRelatedVideos={false}
                          showInfo={false}
                          annotations={false}
                          onEnd={e => {
                            setShowVideo(false)
                          }}
                          modestBranding={1}
                          onPlaying={trackerVideo()}
                        />
                      )}
                    </>
                  )}

                  {showVideo ? null : (
                    <strong
                      className="play-btn"
                      onClick={e => {
                        // setPlaying(true)
                        playVideoHandler()
                      }}
                    >
                      <i className="icon-play"></i>
                    </strong>
                  )}
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
      <div ref={videoRef}>
        <PlayVideo
          isOpen={isPlaying}
          isCloseFunction={setPlaying}
          videoId={getVideoId(props?.data?.Embed_Video_Url)}
          // videoId={getVideoId(props?.data?.Embed_Video_Url)}
          isAutoPlay={1}
          //   key={"sas"}
        />
        <strong
          className="play-btn close-icon"
          onClick={() => {
            setPlaying(false)
            videoRef.current.classList.remove("fullscreen-video")
          }}
        >
          <i className="icon-close" />
        </strong>
      </div>
    </React.Fragment>
  )
}

export default React.memo(AboutMarket)
