import React, { useState } from "react"
import "./VideoBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"
import PlayVideo from "../../Play/PlayVideo"
import Img from "gatsby-image"
// import getVideoId from "get-video-id"
import { getVideoId } from "../../utils"

import videoUrl from "./video_bg.mp4"
import YouTube from "@u-wave/react-youtube"

const videoRef = React.createRef(null)

const VideoBanner = props => {
  const [isPlaying, setPlaying] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const playVideo = () => {
    var elem = videoRef.current
    // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
    // if(fullScreenAvailable) {
    //     if (elem.requestFullscreen) {
    //         elem.requestFullscreen();
    //     } else if (elem.webkitRequestFullscreen) { /* Safari */
    //         elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) { /* IE11 */
    //         elem.msRequestFullscreen();
    //     }
    // } else {
    //     elem.classList.add("fullscreen-video")
    // }
    elem.classList.add("fullscreen-video")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formClick",
      formType: "form-video",
      formId: "local-living-video",
      formName: "Local Living Video",
      formLabel: window.location.href,
    })
  }

  const trackerVideo = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: event,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }

  const videoId = getVideoId("https://www.youtube.com/watch?v=p-VnbFs71gE")

  const playVideoHandler = () => {
    // setPlaying(true)
    setShowVideo(true)
  }

  const closeVideo = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
    setPlaying(false)
  }

  function fullScreenHandler() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    if (fullscreenElement == null) {
      setPlaying(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", fullScreenHandler, false)
    document.addEventListener(
      "webkitfullscreenchange",
      fullScreenHandler,
      false
    )
    document.addEventListener("MSFullscreenChange", fullScreenHandler, false)
    document.addEventListener("mozfullscreenchange", fullScreenHandler, false)
  }, [])

  return (
    <React.Fragment>
      {!props.Bg_Video?.url && !showVideo ? (
        <>
          <div className="video-banner position-relative">
            <Img
              fluid={props?.image?.url_sharp?.childImageSharp.fluid}
              alt={props?.image?.alternativeText}
              className="video-bg-img"
            />

            <ScrollAnimation animateIn="fadeIn" animateOnce>
              <strong
                className="play-btn"
                onClick={e => {
                  setPlaying(true)
                  playVideo()
                }}
              >
                <i className="icon-play"></i>
              </strong>
            </ScrollAnimation>
          </div>
          <div ref={videoRef}>
            <PlayVideo
              isOpen={isPlaying}
              isCloseFunction={setPlaying}
              videoId={getVideoId(props?.video)}
              isAutoPlay={1}
            />
            <strong
              className="play-btn close-icon"
              onClick={() => {
                setPlaying(false)
                videoRef.current.classList.remove("fullscreen-video")
              }}
            >
              <i className="icon-close" />
            </strong>
          </div>
        </>
      ) : (
        <>
          <div className="video-banner position-relative">
            <ScrollAnimation className="relative" animateOnce={true}>
              {props.Bg_Video?.url && !showVideo ? (
                <div className="video-container">
                  <video
                    data-autoplay
                    playsinline="true"
                    autoplay="true"
                    muted="true"
                    loop="true"
                    preload="auto"
                    style={{ width: "100%" }}
                  >
                    <source src={props.Bg_Video?.url} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <>
                  {showVideo && (
                    <div
                      onClick={e => {
                        setShowVideo(false)
                      }}
                      className="youtube-close-button"
                    >
                      X
                    </div>
                  )}
                  {showVideo && (
                    <YouTube
                      video={getVideoId(
                        "https://www.youtube.com/watch?v=p-VnbFs71gE"
                      )}
                      autoplay
                      showRelatedVideos={false}
                      showInfo={false}
                      annotations={false}
                      onEnd={e => {
                        setShowVideo(false)
                      }}
                      modestBranding={1}
                      onPlaying={trackerVideo()}
                    />
                  )}
                </>
              )}
            </ScrollAnimation>
            {showVideo ? null : (
              <ScrollAnimation animateIn="fadeIn" animateOnce>
                <strong
                  className="play-btn"
                  onClick={e => {
                    // setPlaying(true)
                    playVideoHandler()
                  }}
                >
                  <i className="icon-play"></i>
                </strong>
              </ScrollAnimation>
            )}
          </div>

          <div ref={videoRef}>
            <PlayVideo
              isOpen={isPlaying}
              isCloseFunction={setPlaying}
              videoId={getVideoId(
                "https://www.youtube.com/watch?v=p-VnbFs71gE"
              )}
              isAutoPlay={1}
            />
            <strong
              className="play-btn close-icon"
              onClick={() => {
                setPlaying(false)
                videoRef.current.classList.remove("fullscreen-video")
              }}
            >
              <i className="icon-close" />
            </strong>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default VideoBanner
