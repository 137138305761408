import * as React from "react";
import "./ContactForm.scss";
import ContactFormPage from "../forms/contact";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import FormHeader from "../ContactForm/FormHeader";
import FormFooter from "../ContactForm/FormFooter";
import ValuationPage from "../ContactForm/ValuationPage";

const ContactOrValuationForm = (props) => {
    console.log(props);
    const formRef = React.createRef(null);
    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    }  
    const wrapClass = (props?.data?.Pagename === "Property Valuation" ? 'valuation-page-wrap' :'') 
    return (
        <React.Fragment>
            <div className={`contact-form-sec ${wrapClass}`}>
                <ImageTransform
                    imagesources={props?.data?.Banner_Image?.url}
                    renderer="srcSet" imagename="articles.Banner_Image.fullpage"
                    attr={{ alt: props?.data?.Banner_Image?.alternativeText, className: 'contact-bg' }}
                    imagetransformresult={processedImages}
                    id={props?.data?.id}
                />
                {props?.data?.Pagename === "Property Valuation" ? 
                    <ValuationPage {...props} />                     
                    :
                    <div className="contact-form" ref={formRef}>                    
                        <FormHeader title={props?.data?.SM_Title}  subtitle={props?.data?.title} />                    
                        <ContactFormPage {...props}/> 
                        <FormFooter reviews= {props?.reviews} />
                    </div>
                }
            </div>
        </React.Fragment>
    );
};
export default ContactOrValuationForm;
