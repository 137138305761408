import React from "react";
import { Container,Row,Col } from 'react-bootstrap';
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import "../AllModules/Featured.scss"

const Featured = (props) =>{
  var {pathname} = useLocation();
  return pathname && pathname === "/holiday-homes/" ? (
    
      <div className="featured-container">
        <Container>
          <Row>
            <Col className="md-12">
              <h2 className="featured-title">{props.data.Title}</h2>
              <div className="image-wrapper-grid">
                   {props.data.Logos.map((items, i) => (
                   <div key={i} className="image-card">
                     <Link to={items.siteurl} target="_blank">
                        <img src={items.Image[0].url} />
                     </Link>
                  </div>
          ))}
            </div>
            </Col>
          </Row>
       </Container>
      </div>
   
  ) : (
    ""
  )   
}
export default Featured;