import { Link } from "gatsby";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import Slider from "react-slick";
import "./CustomerStories.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";

const CustomerStories = (props) => {
    const settings1 = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1199,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                },
            },
        ],
    };
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                customerStories(where: { Publish: true }) {
                    Name
                    Who
                    id
                    Video_Url
                    Tile_Image {
                        id
                        url
                    }
                }
            }
        }
    `)
    return (
        <React.Fragment>
            <div className="customer-stories">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="stories-head d-flex justify-content-between align-items-center">
                                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                    <h2 className="main-title">{props?.data?.Title}</h2>
                                </ScrollAnimation>
                            </div>
                            <Slider className="stories-slider d-block" {...settings1}>
                                {data?.glstrapi?.customerStories.map((each, i) => 
                                    <div className="img-item">
                                        <ScrollAnimation animateIn="fadeInUp" delay={i*100} animateOnce>
                                            <VideoCard
                                                cardTitle={each?.Name}
                                                cardImg={each?.Tile_Image?.url}
                                                cardImgAlt={each?.Tile_Image?.alternativeText}
                                                searchLink={each?.Who}
                                                video={each?.Video_Url}
                                                vimeo={false}
                                                playInline={true}
                                            />
                                        </ScrollAnimation>
                                    </div>
                                )}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default CustomerStories;
