import * as React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const FormHeader = (props) => {
    return (
        <div className="form-head">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h1 className="main-title">{props?.title}</h1>
                <p className="desc">
                    {props?.subtitle}
                </p>
            </ScrollAnimation>
        </div>
    )
};
export default FormHeader;