import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import googleReview from "../../../images/google-reviews-dark.png";
import googleReviewLogo from "../../../images/googleReview.png";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "axios";
import moment from "moment";
import Slider from "react-slick";
import "./UserRating.scss";

const UserRating = (props) => {
    const [review, setReviews] = React.useState([]);
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};

    React.useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews?_limit=10`
        getitems(url);
    }, []);
    
    const getitems = async url => {
        try {
            const { data } = await Axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})// could be from env files 
            const reviews = data.filter(rev => rev.comment != null && (rev.starRating === "FOUR" || rev.starRating === "FIVE"));
            setReviews(reviews.slice(0, 5));
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const starRating = (rating)=>{
        var star = [];
        for (let i = 0; i < rating; i++) {
            star.push(<i className="icon-star-fill"/>);  
        }
        return star;
    }

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Google_Review_Image {
                        url
                        alternativeText
                    }
                }
            }
        }
    `)   
    const settings = {
        dots: false,
        speed: 800,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              autoplay: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
                dots: true,
                arrows:false
            },
          },
        ],
      }
    return (
        <div className="user-rating d-xl-flex">
        <div className="user-banner img-zoom">
            <img src={data?.glstrapi?.siteConfig?.Google_Review_Image?.url} alt={data?.glstrapi?.siteConfig?.Google_Review_Image?.alternativeText} />
        </div>
        <div className="user-details">
            {review.length > 0 && 
            <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <div className="google-reviews d-flex align-items-center">
                    <img className="google-review-logo" src={googleReviewLogo} alt="Google"/>
                    <h1>Reviews</h1>
                </div>
                {props?.pageName === 'Property Valuation' ?
                     <Slider className="card-slider greviews-slider" {...settings}>
                        { review.map((item,index) => (  
                                <>   
                                    <div className="rating-stars">
                                        {starRating(wordToNumber[item?.starRating])}
                                    </div> 
                                    <h3 className="rate-title">
                                        Top marks for efficiency and in understanding our requirements.{" "}
                                    </h3>
                                    <p className="rate-desc">{item.comment}</p>
                                    <span className="user-name d-block">{item?.reviewer?.displayName}</span>
                                    <span className="review-time d-block">{moment(item.updateTime).startOf("day").fromNow()}</span>
                                </>
                        ))}
                    </Slider>
                    :
                    <React.Fragment>
                    <div className="rating-stars">
                        {starRating(wordToNumber[review[0]?.starRating])}
                    </div>
                    <h3 className="rate-title">
                        Top marks for efficiency and in understanding our requirements.{" "}
                    </h3>
                    <p className="rate-desc">
                        {/* {review?.comment}     
                        Sven showed my family the best apartments in Dubai Marina. He gave us most helpful advice and opportunities .  I would definitely recommend to anyone looking to buy real estate in Dubai. Thank you Sven! Sven showed my family the best apartments in Dubai Marina. He gave us most helpful advice and opportunities .  I would definitely recommend to anyone looking to buy real estate in Dubai. Thank you Sven!Sven showed my family the best apartments in Dubai Marina. He gave us most helpful advice and opportunities .  I would definitely recommend to anyone looking to buy real estate in Dubai. Thank you Sven!*/}
                        {review[0]?.comment}
                    </p>
                    <span className="user-name d-block">{review[0]?.reviewer?.displayName}</span>
                    <span className="review-time d-block">{moment(review[0].updateTime).startOf("day").fromNow()}</span>
                    </React.Fragment>
                }             

                <div className="rvw-wrp d-md-flex align-items-end justify-content-between">
                    <div className="google-reviews d-flex align-items-center">
                        <img className="google-logo" src={googleReview} alt="Google" width={90}/>
                        <span className="rated-txt">
                            Rated <strong>{props?.reviews?.average}/5</strong>{" "}
                        </span>
                    </div>
                    <Link className="team-link text-uppercase" to="/about-espace/reviews/">read more reviews</Link>
                </div>
            </ScrollAnimation>
            }
        </div>
    </div>
    );
};

export default UserRating;
