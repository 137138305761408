import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ElfsightWidget } from 'react-elfsight-widget';
import "./ElfsightYoutubeWidget.scss";

const ElfsightYoutubeWidget = (props) => { 
    const youtubeWidgetId = props.WidgetId;
    return (
        <div className="elfsight-youtube-widget-wrapper">
            <Container className="">
                <Row>
                    <Col md={12}>                      
                        <ElfsightWidget widgetID={youtubeWidgetId} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default ElfsightYoutubeWidget;