import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Breadcrumb, Dropdown } from "react-bootstrap";
import ServiceCard from "../../Home/ServiceCard/ServiceCard";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";

import "./Insights.scss";

const BlogArticles = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                blogArticles(sort: "Sort", where: {Publish: true}) {
                    id
                    Title
                    Tile_Image {
                        id
                        url
                    }
                    Category {
                        Category
                        id
                    }
                    URL
                    Published_Date
                }
                blogCategories(sort: "Sort", where: {Publish: true}) {
                    Category
                    Sort
                    id
                }
            }
        }
    `);
    const { blogArticles, blogCategories } = data.glstrapi;
    const [ category, setCategory ] = React.useState("all")
    const filteredInsights = category === "all" ? blogArticles : blogArticles.filter(c => c.Category.id == category);
    return (
        <div className="insights-wrp">
            <div className="stories-head">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="d-flex d-md-block d-xl-flex justify-content-between align-items-center">
                                    <h2 className="main-title">{props?.page?.Pagename}</h2>
                                    <div className="select-boxes d-md-none">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link">All {props?.page?.Pagename}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {blogCategories.map((each, i) => 
                                                    <Dropdown.Item key={i}>{each.Category}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <ul className="insight-list d-none d-md-flex">
                                        <li><a href="javascript:;" onClick={() => setCategory("all")} className={category == "all" ? "active" : ""}>All {props?.page?.Pagename}</a></li>
                                        {blogCategories.map((each, i) => 
                                            <li key={i}><a href="javascript:;" className={category == each.id ? "active" : ""} onClick={() => setCategory(each.id)}>{each.Category}</a></li>
                                        )}
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    {filteredInsights.map((each, i) => 
                        <Col xl={i%5 == 0 ? 7 : i%5 == 1 ? 5 : 4} md={6} xs={12} className={(i%5==0 || i%5==1) ? "insights-cards mb-1" : "insights-slider mb-1"} key={i}>
                            <div className="img-item">
                                <ScrollAnimation animateIn={i%2 == 0 ? "fadeInLeft" : "fadeInRight"} animateOnce>
                                    <Link to={`/knowledge-centre/blogs/${each.URL}/`}>
                                        <ServiceCard 
                                            cardImg={each.Tile_Image?.url}
                                            cardDesc={each.Title}
                                            date={each.Published_Date}
                                        />
                                    </Link>
                                </ScrollAnimation>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};
export default BlogArticles;
