import { Link } from "gatsby";
import * as React from "react";
import { Button, Form, Container, Row, Col, Accordion, Dropdown, Nav, Tab } from "react-bootstrap";
import ServiceCard from "../../Home/ServiceCard/ServiceCard";

import "./Teams.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import { GetURL } from "../../utils";
import { take } from "lodash";
import $ from "jquery";

const Teams = (props) => {
    React.useEffect(() => { $("a[href='#']").removeAttr("href"); }, [])
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                teams(sort: "Sort", where: {Publish: true}) {
                    id
                    Name
                    Tile_Image {
                        alternativeText
                        url
                    }
                    Designation
                    imagetransforms
                    URL
                    Video_Url
                    Sort
                    Team_Category{
                        Title
                        id
                        Sort
                    }
                }
                teamsCategories(sort: "Sort", where: {Publish: true}) {
                    Title
                    id
                }            
            }
        }
    `);
    const { teams, teamsCategories } = data.glstrapi;
    const [ category, setCategory ] = React.useState("all");
    const [searhc_string, setSearchString] = React.useState("");
    const teamsSeparation = (props?.page?.Pagename === "About" || props?.page?.Pagename === "Office") ? take(teams, 4) : teams;
    const filteredTeams = category === "all" ? teamsSeparation : teamsSeparation.filter(c => c.Team_Category?.id == category);
    const filterCheck = (name, searhc_string)=>{
        if(name){
            if(name.toLowerCase().includes(searhc_string.toLowerCase())){
                return true
            }
        }
        return false
       
    }
    return (
        <div className="teams-list">
            <Tab.Container defaultActiveKey="all">
                {(props?.page?.Pagename !== "About" && props?.page?.Pagename !== "Office") && <div className="stories-head">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                    <div className="d-flex d-md-block d-xl-flex justify-content-between align-items-center">
                                        <div className="search-form w-xl-25 w-lg-50 w-90 position-relative">
                                            <i className="icon-search"/>
                                            <input 
                                                type="text"
                                                placeholder="Search by the name or position"
                                                value={searhc_string}
                                                className="form-control"
                                                onChange={e => setSearchString(e.target.value)} 
                                            />
                                        </div>
                                        <div className="select-boxes d-md-none">
                                            <Dropdown>
                                                <Dropdown.Menu>
                                                    {teamsCategories.map((each, i) => 
                                                        <Dropdown.Item key={i}>{each.Title}</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Nav variant="pills" as="ul" className="insight-list d-none d-md-flex">
                                            {teamsCategories.filter(c => teams.some(d => c.id === d.Team_Category?.id)).length > 0 && <Nav.Item as="li">
                                                <Nav.Link
                                                    onClick={() => setCategory("all")}
                                                    className={category == "all" ? "active" : ""}
                                                    eventKey="all"
                                                >
                                                    All
                                                </Nav.Link>
                                            </Nav.Item>}
                                            {teamsCategories.filter(c => teams.some(d => c.id === d.Team_Category?.id)).map((each, i) => 
                                                <Nav.Item as="li" key={i}>
                                                    <Nav.Link 
                                                        className={(category === each.id) ? "active" : ""}
                                                        onClick={() => setCategory(each.id)}
                                                        eventKey={each.id}
                                                    >
                                                        {each.Title}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )}
                                        </Nav>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                </div>}
                    
                {(props?.page?.Pagename !== "Meet Our Team" && filteredTeams?.filter(c => c.Name.toLowerCase().includes(searhc_string.toLowerCase()) || c.Designation.toLowerCase().includes(searhc_string.toLowerCase())).length > 0) && 
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="stories-heading d-flex flex-row justify-content-between align-items-center">
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                        <h2 className="main-title">{props?.data?.Title}</h2>
                                    </ScrollAnimation>
                                    <Link to={`/${GetURL(props?.data?.Cta_Link?._id)}`} className="view-more text-uppercase">
                                        view more
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <Tab.Content>
                                    <Tab.Pane eventKey="all">
                                        <div className="row leader-slider">
                                            {filteredTeams?.filter(c => filterCheck(c.Name, searhc_string) || filterCheck(c.Designation, searhc_string)).map((team, i) => {
                                                let processedImages = null;
                                                if (team?.imagetransforms?.Tile_Image_Transforms) {
                                                    processedImages = team?.imagetransforms?.Tile_Image_Transforms;
                                                }
                                                return (
                                                    <Col xl={3} lg={4} md={6} xs={6} key={team?.id}>
                                                        <div className="img-item">
                                                            <ServiceCard 
                                                                cardImg={team?.Tile_Image?.url}
                                                                imgAlt={team?.Name + ", " + team?.Designation + " - Espace"}
                                                                cardTitle={team.Name}
                                                                designation={team.Designation}
                                                                imagename="teams.Tile_Image.tile"
                                                                navigateLink={`/about-espace/meet-the-team/${team.URL}/`}
                                                                processedImages={team?.imagetransforms?.Tile_Image_Transforms ? team?.imagetransforms?.Tile_Image_Transforms : null}
                                                                imagetransforms={true}
                                                                id={team.id}
                                                                video={team?.Video_Url}
                                                                vimeo={false}
                                                                playInline={true}
                                                            />
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                            {filteredTeams?.filter(c => filterCheck(c.Name, searhc_string) || filterCheck(c.Designation, searhc_string)).length === 0 && 
                                                <p className="text-center mt-3">Sorry, No results found</p>
                                            }
                                        </div>
                                    </Tab.Pane>
                                    {teamsCategories.map((eKey, k) => 
                                        <Tab.Pane eventKey={eKey.id} key={eKey.id}>
                                            <div className="row leader-slider">
                                                {filteredTeams?.filter(c => filterCheck(c.Name, searhc_string) || filterCheck(c.Designation, searhc_string)).map((team, i) => {
                                                    let processedImages = null;
                                                    if (team?.imagetransforms?.Tile_Image_Transforms) {
                                                        processedImages = team?.imagetransforms?.Tile_Image_Transforms;
                                                    }
                                                    return (
                                                        <Col xl={3} lg={4} md={6} xs={6} key={team?.id}>
                                                            <div className="img-item" key={team.id}>
                                                                <ServiceCard 
                                                                    cardImg={team?.Tile_Image?.url}
                                                                    imgAlt={team?.Name + ", " + team?.Designation + " - Espace"}
                                                                    cardTitle={team.Name}
                                                                    designation={team.Designation}
                                                                    imagename="teams.Tile_Image.tile"
                                                                    navigateLink={`/about-espace/meet-the-team/${team.URL}/`}
                                                                    processedImages={processedImages}
                                                                    imagetransforms={(team?.Tile_Image?.url)?true:false}
                                                                    id={team.id}
                                                                    video={team?.Video_Url}
                                                                    vimeo={false}
                                                                />
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </div>
                                            {filteredTeams?.filter(c => c.Team_Category?.id === eKey.id).filter(c => c.Name.toLowerCase().includes(searhc_string.toLowerCase()) || c.Designation.toLowerCase().includes(searhc_string.toLowerCase())).length === 0 && 
                                                <p className="text-center mt-3">Sorry, No results found</p>
                                            }
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </Tab.Container>
        </div>      
    );
};
export default Teams;
