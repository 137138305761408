import { Link } from "gatsby"
import React from "react"
import Slider from "react-slick"
import { Container, Row, Col } from "react-bootstrap"
import ServiceCard from "../ServiceCard/ServiceCard"
import "./AgencyService.scss"
import ScrollAnimation from "react-animate-on-scroll"
import { GetURL } from "../../utils"

const AgencyService = props => {
  const settings1 = {
    dots: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    centerMode: false,
    variableWidth: true,
    centerPadding: "18px",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: true,
          autoplay: false,
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <React.Fragment>
      <div className="agency-service aboutus-pgs">
        <Container>
          <Row>
            <Col lg={6}>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <h2 className="main-title">
                  Dubai <span> Real Estate Agency </span> Property Services
                </h2>
              </ScrollAnimation>
            </Col>
            <Col lg={6}>
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <p className="service-desc">{props?.data?.Description}</p>
                <Link
                  to={`/${GetURL(props?.data?.Sm_Title_Link?.id)}`}
                  className="team-link text-uppercase"
                >
                  {props?.data?.Sm_Title}
                </Link>
              </ScrollAnimation>
            </Col>
          </Row>
          <div className="card-slider">
          <Slider className="banner-imgslider d-block" {...settings1}>
            {props?.data?.Service_Cards.map((service, i) => (
              <div
                className={`img-item`}
                key={i}
              >
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={(i + 1) * 100}
                  animateOnce
                >
                  <ServiceCard
                    cardImg={service?.Tile_Image?.url}
                    fluidImg={service?.Tile_Image?.url_sharp}
                    imgAlt={service?.Tile_Image?.alternativeText}
                    cardTitle={service?.S_Title}
                    cardDesc={service?.Description}
                    agencyNavLabel={service?.Cta_Label}
                    agencyNavLink={
                      service?.Cta_Label == "property search"
                        ? "/properties-for-sale/in-dubai/"
                        : `/${GetURL(service?.Cta_Link?.id)}`
                    }
                  />
                </ScrollAnimation>
              </div>
            ))}
          </Slider>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AgencyService