import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import googleReview from "../../../images/module/google-review.svg"
import ReactReadMoreReadLess from "react-read-more-read-less";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import moment from "moment";
import "./Reviews.scss";

const Reviews = (props) => {
    const [reviewList, setCustomerReview] = useState([]);
    const [allReviewList, setReviews] = useState([]);
    let [isLess, setLess] = useState(false);

    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};

    let intialNumberOfReview = 10;

    const getitems = async url => {
        try {
            const { data } = await axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})// could be from env files 
            const reviews = data.filter(rev => rev.comment != null && (rev.starRating === "FOUR" || rev.starRating === "FIVE"));
            setReviews(reviews);
            setCustomerReview(reviews.slice(0, intialNumberOfReview));
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const loadMore = () => {
        let primeryLength = reviewList.length;
        let newList = allReviewList.slice(
            0,
            primeryLength + intialNumberOfReview
        );
        setCustomerReview(newList);
        if (newList.length === allReviewList.length) {
            setLess(true);
        } else {
            setLess(false);
        }
    };

    const starRating = (rating)=>{
        var star = [];
        for (let i = 0; i < rating; i++) {
            star.push(<i className="icon-star-fill"/>);  
        }
        return star;
    }

    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
        getitems(url);
    }, []);

    return (
        <div className="google-review-sec mt-5">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="total-reviews">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="d-md-flex justify-content-between">
                                    <span className="review-logo"><img src={googleReview}  /></span>
                                    <div className="info">
                                        <h4 className="info-title">Rated <span>{props?.reviews?.average}/5</span> from over {props?.reviews?.totalCount-10}+ of our valued customers.</h4>
                                        <p className="info-desc">We’d love to hear from you. <a href={props?.reviews?.link} target="_blank">Write a review</a> </p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <div className="user-reviews d-md-flex flex-wrap justify-content-between">
                                {reviewList.map((review, i) =>  
                                    <div className="review-card pt-5" key={i}>
                                        <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                            <div className="stars">
                                                {starRating(wordToNumber[review.starRating])}
                                            </div>
                                            <p className="review-desc">
                                                <ReactReadMoreReadLess
                                                    charLimit={300}
                                                    readMoreText={"Read more"}
                                                    readLessText={"Read less"}
                                                    readMoreClassName="read-more-less"
                                                    readLessClassName="read-more-less"
                                                >
                                                    {review.comment}
                                                </ReactReadMoreReadLess>
                                            </p>
                                            <div className="contact-agent d-flex justify-content-between">
                                                <div className="contact-agent-photo">
                                                    <img src={review.reviewer?.profilePhotoUrl} alt="image" width={64}/>
                                                </div>
                                                <div className="contact-agent-info">
                                                    <span className="contact-agent-name d-block">
                                                        {review.reviewer?.displayName}
                                                    </span>
                                                    <a className="contact-agent-number">
                                                        {moment(review.updateTime).startOf("day").fromNow()}
                                                    </a>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    {reviewList.length > 0 && <Col md={12}>
                        <div className="d-flex justify-content-center my-5">
                            {!isLess ? (
                                <button className="btn subscribe-btn btn-media" onClick={loadMore}>
                                    Load More
                                </button>
                            ) : null}
                        </div>
                    </Col>}
                </Row>
            </Container>
        </div>
    );
};
export default Reviews;
