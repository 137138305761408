import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import ServiceCard from "../../Home/ServiceCard/ServiceCard";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";
import "./Insights.scss";
import ElfsightYoutubeWidget from "../../AllModules/ElfsightWidgets/ElfsightYoutubeWidget";

const Insights = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                insights(sort: "Sort", where: {Publish: true}) {
                    id
                    Title
                    Tile_Image {
                        id
                        url
                    }
                    Category {
                        Category
                        id
                    }
                    Url
                    Published_Date
                }
                blogs(sort: "Sort", where: {Publish: true})  {
                    id                    
                    Title
                    Image {
                      alternativeText
                      url
                    }  
                    Url
                    Published_Date: published_at
                }
                newsCategories(sort: "Sort", where: {Publish: true}) {
                    Category
                    Sort
                    id
                }
            }
        }
    `);
    const { insights, newsCategories, blogs } = data.glstrapi;
    const [category, setCategory] = React.useState("all")


    var newBlogList = [] 
    if(blogs && blogs.length > 0){
        newBlogList = _.map(blogs, function (element) {
            return _.extend({}, element, { Category: { id: 'blog', Category: "Blogs" }, Tile_Image: {url : (element?.Image)? element?.Image[0]?.url : null }});
        });
    }
    
    const insightsMergeList = _.orderBy(_.concat(insights, newBlogList), ['Published_Date'], ['desc']);
    const filteredInsights = category === "all" ? insightsMergeList : insightsMergeList.filter(c => c.Category.id == category);
    return (
        <div className="insights-wrp">
            <div className="stories-head">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="d-flex d-md-block d-xl-flex justify-content-between align-items-center">
                                    <h1 className="main-title">{props?.page?.Pagename}</h1>
                                    <div className="select-boxes d-md-none">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link">{newsCategories.find(c => c.id === category)?.Category || "All " + props?.page?.Pagename}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {newsCategories.map((each, i) =>
                                                    <Dropdown.Item
                                                        key={i}
                                                        onClick={() => setCategory(each.id)}
                                                    >
                                                        {each.Category}
                                                    </Dropdown.Item>
                                                )}
                                                 <Dropdown.Item onClick={() => setCategory("blog")}>
                                                    Blog
                                                 </Dropdown.Item>
                                                 <Dropdown.Item onClick={() => setCategory("video")}>
                                                    Video
                                                 </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <ul className="insight-list d-none d-md-flex">
                                        <li><a onClick={() => setCategory("all")} className={category == "all" ? "active" : ""}>All {props?.page?.Pagename}</a></li>
                                        {newsCategories.map((each, i) =>
                                            <li key={i}><a className={category == each.id ? "active" : ""} onClick={() => setCategory(each.id)}>{each.Category}</a></li>
                                        )}
                                        <li><a onClick={() => setCategory("blog")} className={category == "blog" ? "active" : ""}>Blog </a></li>
                                        <li><a onClick={() => setCategory("video")} className={category == "video" ? "active" : ""}>Video </a></li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    {
                        category === 'video' ? 
                        <ElfsightYoutubeWidget WidgetId="fec30bb3-ff0b-425a-9d0d-89e1bc0a7860" />
                            :
                            <>
                            {filteredInsights.map((each, i) =>
                                <>
                                {category === 'all' && i === 5 && 
                                    <>
                                        <Col xl ={ 12} md={6} xs={12} className="insights-youtube-widget mb-md-5" key={i}>
                                            <ElfsightYoutubeWidget WidgetId="b084f357-07d7-4f67-a748-8278bc707bd6" />
                                        </Col>
                                    </>                                  
                                }
                                <Col xl={i % 5 == 0 ? 7 : i % 5 == 1 ? 5 : 4} md={6} xs={12} className={(i % 5 == 0 || i % 5 == 1) ? "insights-cards mb-1" : "insights-slider mb-1"} key={i}>
                                    <div className="img-item">
                                        <Link to={`/knowledge-centre/latest-property-news/${each.Url}/`} className="w-100">
                                            <ServiceCard
                                                cardImg={each.Tile_Image?.url}
                                                imgAlt={"Latest News " + each.Title + " - Espace"}
                                                cardDesc={each.Title}
                                                date={each.Published_Date}
                                            /> 
                                        </Link>
                                    </div>
                                </Col></>
                            )}</>
                    }
                    
                    
                </Row>
            </Container>
        </div>
    );
};
export default Insights;
