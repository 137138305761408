import { Link, navigate } from "gatsby";
import { isEmpty, find } from 'lodash';
import * as React from "react";
import { Button, Form, Container, Row, Col, Modal } from "react-bootstrap";
import $ from "jquery";
import "./ModuleBanner.scss";
// import SearchInputBox from '../../predictive-search/search-inputbox';
import googleReview from "../../../images/google-reviews-white.png";
import ScrollAnimation from "react-animate-on-scroll";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import SearchInputBox from "../../predictive-search/search-multiareas";
import AreaListBox from "../../SearchResult/FilterBlock/AreaList";
import { createSearchUrlFromParams } from "../../SearchResult/FilterBlock/functions"
import ContactFormPage from "../../forms/contact";
import PlayVideo from "../../Play/PlayVideo";
import { GetURL, getVideoId } from "../../utils";

const videoRef = React.createRef(null);

const ModuleBanner = (props) => {
    const [emailModal, setEmailModal] = React.useState(false);
    const [areasArr, setAreasArr] = React.useState([]);
    const [areasList, setAreasList] = React.useState([]);

    const [isPlaying, setPlaying] = React.useState(false);

    const handleMultiAreaFun = (val) => {
        let areas = [];
        // find
        if (isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }

    function handleSubmit(e, search_type) {
        e.preventDefault();
        let customValue = [];
        if (areasArr) {
            areasArr.map((a) => {
                if (a) {
                    customValue.push(a.slug)
                }
            })
        }

        let params = {
            areas: customValue,
            search_type: search_type
        }
        navigate(createSearchUrlFromParams(params));
    }

    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    }

    const handleClick = (label) => {
        if(label.toLowerCase() === "email") {
            setEmailModal(true)
        }
        if(label.toLowerCase() === "current vacancies") {
            let crEl = document.getElementById("careers-list-ct");
            if(crEl != null) {
                const y = crEl.getBoundingClientRect().top - 150;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    const playVideo = () => {
        var elem = videoRef.current;
        // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        // if(fullScreenAvailable) {
        //     if (elem.requestFullscreen) {
        //         elem.requestFullscreen();
        //     } else if (elem.webkitRequestFullscreen) { /* Safari */
        //         elem.webkitRequestFullscreen();
        //     } else if (elem.msRequestFullscreen) { /* IE11 */
        //         elem.msRequestFullscreen();
        //     }
        // } else {
        //     elem.classList.add("fullscreen-video")
        // }
        elem.classList.add("fullscreen-video")
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'local-living-video',
            'formName': "Local Living Video",
            'formLabel': window.location.href
        });
    }

    const closeVideo = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setPlaying(false)
    }


    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            setPlaying(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);

    return (
        <div className="modules-banner">
            <picture className="position-relative">
                <ImageTransform
                    imagesources={props?.data?.Banner_Image?.url}
                    renderer="srcSet" imagename="articles.Banner_Image.banner"
                    attr={{ alt: props?.data?.Banner_Image?.alternativeText, className: '' }}
                    imagetransformresult={processedImages}
                    className="bg-img"
                    id={props?.data?.id}
                />
                <div className="bg-gradient-module"/>
                {/* <img className="bg-img" src={props?.data?.Banner_Image?.url} alt={props?.data?.Banner_Image?.alternativeText} /> */}
            </picture>
            <div className="banner-content">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <Form className="banner-form" onSubmit={(e) => e.preventDefault()}>
                                    <h1 className="sub-title text-uppercase">{props?.data?.SM_Title}</h1>
                                    <h2 className="main-title">{props?.data?.Title}</h2>
                                    {(props?.data?.Cta_1_Label || props?.data?.Cta_2_Label) &&
                                        <div className="d-flex flex-md-row flex-column mb-5 d-md-block sticky-mobile-btn">
                                            {props.data.Cta_Video_Link &&
                                                <button className="btn subscribe-btn btn-secondary" 
                                                    onClick={() => {
                                                       setPlaying(true);
                                                       playVideo()
                                                    }}
                                                >
                                                    <i className="icon-play-white d-md-block d-none"/>{props.data.Cta_Video_Link}
                                                </button>
                                            }
                                            {(props.data.Cta_1_Label) &&
                                                <React.Fragment>
                                                    {props.data.Cta_1_Link ?
                                                    <Link to={`/${GetURL(props.data.Cta_1_Link.id)}`} className="btn subscribe-btn btn-secondary">
                                                        {props.data.Cta_1_Label}
                                                    </Link> : 
                                                    <a onClick={() => handleClick(props.data.Cta_1_Label)} className="btn subscribe-btn btn-secondary">
                                                        {props.data.Cta_1_Label}
                                                    </a>}
                                                </React.Fragment>
                                            }
                                            {props.data.Cta_2_Label != null &&
                                                <React.Fragment>
                                                    {props.data.Cta_2_Label.toLowerCase() === "call" && 
                                                        <a href={`tel:${props?.office_ph}`} className="btn subscribe-btn btn-outline-light ms-md-4 ms-0 mt-md-0 mt-3">
                                                            {props.data.Cta_2_Label}
                                                        </a>
                                                    }
                                                    {props.data.Cta_2_Label.toLowerCase() === "current vacancies" && 
                                                        <a onClick={() => handleClick(props.data.Cta_2_Label)} className="btn subscribe-btn btn-outline-light ms-md-4 ms-0 mt-md-0 mt-3">
                                                            {props.data.Cta_2_Label}
                                                        </a>
                                                    }
                                                    {props.data.Cta_2_Link && 
                                                        <Link to={`/${GetURL(props.data.Cta_2_Link.id)}`} className="btn subscribe-btn btn-outline-light ms-md-4 ms-0 mt-md-0 mt-3">
                                                            {props.data.Cta_2_Label}
                                                        </Link>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                    {props?.data?.Show_Search &&
                                        <Form.Group controlId="formBasicEmail" className="banner-search-predictive">
                                            <div className="home-searcch-box-wrap">
                                                <div className="position-relative">
                                                    <SearchInputBox
                                                        setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun}
                                                    />
                                                    {/* <Form.Control type="text" placeholder="City, community or tower" /> */}
                                                    <Button variant="primary" type="submit">
                                                        <i className="icon-location-white"></i>
                                                    </Button>
                                                </div>
                                                <div className="search-overlay-btn change-type d-none d-md-flex align-items-center justify-content-between">
                                                    <a href="javascript:void(0)" className="text-uppercase sales_overlay_btn" onClick={(e) => handleSubmit(e, 'sales')}>buy</a>
                                                    <a href="javascript:void(0)" className="text-uppercase rent_overlay_btn" onClick={(e) => handleSubmit(e, 'lettings')}>rent</a>
                                                </div>
                                                <div className="change-type d-flex d-md-none align-items-center justify-content-between">
                                                    <a href="javascript:void(0)" className="text-uppercase sales_overlay_btn mobile-search-overlay-btn" onClick={(e) => handleSubmit(e, 'sales')}>buy</a>
                                                    <a href="javascript:void(0)" className="text-uppercase rent_overlay_btn mobile-search-overlay-btn" onClick={(e) => handleSubmit(e, 'lettings')}>rent</a>
                                                </div>
                                            </div>
                                            <AreaListBox areas={areasArr} removeArea={removeArea} />
                                        </Form.Group>
                                    }
                                    
                                    {props?.data?.Show_Review && <p className="review-txt">
                                        <a href={props?.reviews?.link} target="_blank"><em><img src={googleReview} alt="google-logo" /></em></a>
                                        <span>Rated <strong>{props?.reviews?.average}/5</strong> from {props?.reviews?.totalCount} Customer Reviews</span>
                                    </p>}
                                    {
                                        props.data.Alias === "Careers" &&
                                            <Link to={"/about-espace/reviews/"} className="espase-review-link">
                                                Espace Reviews
                                            </Link>
                                    }
                                </Form>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal size="lg" show={emailModal} onHide={() => setEmailModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Contact Espace</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactFormPage/>
                    </Modal.Body>
                </div>
            </Modal>
            <div ref={videoRef}>
                {props?.data?.Cta_Video_Link && <PlayVideo
                    isOpen={isPlaying}
                    isCloseFunction={setPlaying}
                    videoId={getVideoId(props?.data?.Embed_Video_Url)}
                    isAutoPlay={1}
                />}
                <strong className="play-btn close-icon" 
                    onClick={() => { 
                        setPlaying(false); videoRef.current.classList.remove("fullscreen-video")
                    }}>
                    <i className="icon-close"/>
                </strong>
            </div>
        </div>
    );
};
export default ModuleBanner;
