import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ValuationFormPage from "../forms/valuation-form";
import FormHeader from "./FormHeader";
import ContentList from "../ContentList/ContentList";

const ValuationPage = (props) => {
    const formRef = React.createRef(null);    
    return (                
        <div className="valuation-page-form">            
            <Container>
                <Row>
                    <Col lg={8}>
                    <div className="valuation-form contact-form" ref={formRef}>                
                        <FormHeader title={props?.data?.SM_Title}  subtitle={props?.data?.Title} />                    
                        <ValuationFormPage {...props} />                      
                    </div>
                    </Col>
                    <Col lg={4}>
                        <ContentList modules = {props?.modules}/>                        
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};
export default ValuationPage;