import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "../../Home/ServiceCard/ServiceCard";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import "./Calculators.scss";

const Calculators = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                calculators(sort: "Sort", where: {Publish: true}) {
                    Title
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Description
                    URL
                }
            }
        }
    `);
    return (
        <div className="calculators">
            <Container>
                <Row className="calculator-slider justify-content-start">
                    {data.glstrapi.calculators.map((each, i) => 
                        <Col xl={4} md={6} xs={12} key={i} className="px-md-0 px-auto mb-4">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="img-item">
                                    <ServiceCard 
                                        cardTitle={each.Title}
                                        cardImg={each.Tile_Image?.url}
                                        imgAlt={`${each.Title} - Espace`}
                                        cardDesc={each.Description}
                                        searchLink={"View more"}
                                        calculator={true}
                                        navigateLink={`/knowledge-centre/calculators/${each.URL}`}
                                    />
                                </div>
                            </ScrollAnimation>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>      
    );
};
export default Calculators;
