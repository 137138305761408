import { Link } from "gatsby"
import React, { useState } from "react"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap"
import "./ElfsightVideos.scss"
import ScrollAnimation from "react-animate-on-scroll"
import HTMLReactParser from "html-react-parser"

import { ElfsightWidget } from 'react-elfsight-widget';

const ElfsightVideos = props => {

  return (
    <React.Fragment>
      <div className="property-tabsec elfsight-video">
        <Container>
          <Row>
            <Col lg={12}>
              <h2 class="main-title">Latest <span> Videos </span></h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <ElfsightWidget widgetID={`ceea1334-cc8b-4896-9d83-c0b2f5a9f2e0`} />
              </ScrollAnimation>
            </Col>
            <Col lg={6}>
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <ElfsightWidget widgetID={`a1357cbe-1d61-4b18-8267-cdb5dcde300c`} />
              </ScrollAnimation>
            </Col>
          </Row>
          <div className="view-more">
            <Link
              to={`/dubai-property-videos/`}
              className="btn subscribe-btn d-none d-xl-inline-block"
            >
              view all videos 
            </Link>
          </div>



        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ElfsightVideos)
