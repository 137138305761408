import * as React from "react";
import googleLogo from "../../images/google-reviews-dark.svg";


const FormFooter = (props) => {
    return (
        <div className="google-review d-md-flex align-items-center">
            <a href={props?.reviews?.link} target="_blank" className="google-logo">
                <img src={googleLogo} alt="google" />
            </a>
            <span className="d-block">
                Rated {props?.reviews?.average}/5 from {props?.reviews?.totalCount} Customer Reviews
            </span>
        </div>
    )
};
export default FormFooter;