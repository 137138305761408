import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Accordion, Card, } from "react-bootstrap";
import "./IndustryLeader.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import { GetURL } from "../../utils";
import HTMLReactParser from 'html-react-parser';
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useContext } from "react";

const IndustryLeader = (props) => {
    console.log("pagenameprops",props.pageName);
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
        const isCurrentEventKey = currentEventKey === eventKey;
        return (
            <button type="button" className={isCurrentEventKey ? "active card-header position-relative" : "card-header position-relative"} onClick={decoratedOnClick}>
                {children}
                <i className={isCurrentEventKey ? "icon-caretdown up" : "icon-caretdown"}></i>
            </button>
        );
    }
    const settings1 = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        responsive: [
            {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
            },
            {
                breakpoint: 1199,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                }
            },
        ]
      }
      const settings2 = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    }
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                globalModule {
                    Opening_Hours
                    About_Stats {
                        Title
                        Value
                    }
                    Property_Stats {
                        Count
                        Title
                    }
                    Aboutus_Accordion {
                        Title
                        Description
                    }
                }
                siteConfig {
                    Office_Address
                }
            }
        }
    `);
    return (
        <div className="industry-leader">
            <Container>
                <Row>
                    <Col md={12}>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce>
                            <div className={`d-xl-flex flex-wrap ${props?.data?.Choose_Right_Content === "Empty" ? "justify-content-end" : "justify-content-between"}`}>
                                <div className={`industry-desc order-1 ${props?.data?.Choose_Right_Content === "Empty" ? "order-2-industry-desc" : ""}`}>
                                    <h2 className="main-title">{props?.data?.Title}</h2>
                                    {props?.data && <p className="desc">{HTMLReactParser(props.data.Description)}</p>}
                                    {props.pageName === "About" && <Accordion className="menu-accordian mt-5 pt-0 px-0">
                                        {data.glstrapi.globalModule.Aboutus_Accordion.map((each, i) => 
                                            <Card key={i}>
                                                <div>
                                                    <ContextAwareToggle
                                                        eventKey={`${i}`}
                                                        className="accordion-header"
                                                    >
                                                        {each.Title}
                                                    </ContextAwareToggle>
                                                </div>
                                                <Accordion.Collapse eventKey={`${i}`}>
                                                    <div className="accordion-card accordion-card-first card-body">
                                                        <p>{each.Description}</p>
                                                    </div>
                                                    </Accordion.Collapse>
                                            </Card>
                                        )}
                                    </Accordion>}
                                </div>
                                {(props?.data?.Choose_Right_Content !== "Empty" && props.pageName !== "Careers" && props.pageName !== "Holiday Homes") && <ul className="counter-list mt-5 d-flex flex-wrap order-3">
                                    {(props.pageName !== "About") && data.glstrapi.globalModule.Property_Stats.map((each, i) => 
                                        <li key={i}>
                                            <strong>{each.Count}</strong>
                                            <span className="text-uppercase">{each.Title}</span>
                                        </li>
                                    )}
                                    {props.pageName === "About" && data.glstrapi.globalModule.About_Stats.map((each, i) => 
                                        <li key={i}>
                                            <strong>{each.Value}</strong>
                                            <span className="text-uppercase">{each.Title}</span>
                                        </li>
                                    )}
                                </ul>}
                                {props?.data?.Choose_Right_Content === "Explore" && <div className="exp-info order-2">
                                    <h2 className="main-title">{props?.data?.Explore_Title}</h2>
                                    <ul className="exp-list">
                                        {props?.data?.Explore.map((each, i) => each.Link && 
                                            <li key={i}><Link to={`/${GetURL(each?.Link?.id)}`}><i className="icon-caret-circle"></i>{each.Label}</Link></li>
                                        )}
                                    </ul>
                                </div>}
                                {props?.data?.Choose_Right_Content === "Benefits" && 
                                   <div className="exp-info order-2">
                                    {props.pageName === "Holiday Homes" ? <p className="main-title explore-sub-title">WHY YOU SHOULD WORK WITH US</p> : ""}
                                    <h2 className="main-title">{props?.data?.Explore_Title}</h2>
                                    <ul className="exp-list">
                                        {props?.data?.Explore.map((each, i) => 
                                            <li key={i}><i className="icon-caret-circle"></i>{each.Label}</li>
                                        )}
                                    </ul>
                                </div>}
                                {props?.data?.Choose_Right_Content === "Opening_Hours" && <div className="exp-info order-2">
                                    <h2 className="main-title">Office Address</h2>
                                    <ul className="exp-list hours-list pb-0">
                                        <li>
                                            <p>{data.glstrapi.siteConfig.Office_Address}</p>
                                        </li>
                                    </ul>
                                    <hr/>
                                    <h2 className="main-title">Opening Hours</h2>
                                    <ul className="exp-list hours-list">
                                        {HTMLReactParser(data.glstrapi.globalModule.Opening_Hours)}
                                    </ul>
                                </div>}
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>      
    );
};
export default IndustryLeader;
