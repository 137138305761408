import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { Button, Form, Container, Row, Col, Breadcrumb } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import Slider from "react-slick";
import "./Awards.scss";
import blankImg from "../../../images/module/blank-img.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const Awards = (props) => {
    const [year, setYear] = React.useState("");
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                awards(where: {Publish: true}) {
                    Title
                    id
                    Category
                    Date
                    Description
                    Image {
                        url
                        alternativeText
                    }
                    Embed_Video_Url
                    Year
                }
            }
        }
    `);

    const settings = {
        dots: false,
        speed: 800,
        autoplay:true,
        autoplaySpeed:1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        centerMode: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                }
            },
        ]
    }

    const { awards } = data.glstrapi;
    const years = [...new Set(awards.map(c => c.Year))];

    React.useEffect(() => {
        setYear(years[0])
    }, []);

    const navigate = (yr) => {
        let el = document.getElementById(`awards-${yr}`);
        if(el) {
            el.scrollIntoView()
        }
    }

    return (
        <div className="awards-landing">
            <div className="year-list">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <ul className="tag-list">
                                    {years.map((yr, i) => 
                                        <li className="cursor-pointer" key={i} onClick={() => navigate(yr)}>
                                            <a className={`text-uppercase ${year === yr ? "active" : ""}`}>{yr}</a>
                                        </li>
                                    )}
                                </ul>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    {years.map((yr, i) => 
                        <Col md={12} key={i} className="position-relative">
                            <div className="va-hidden" id={`awards-${yr}`}/>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <h4 className="year-txt">{yr}</h4>
                            </ScrollAnimation>
                            <Slider className="yearprop-slider d-block" {...settings}>
                                {awards.filter(c => c.Year == yr).map((award, k) => 
                                    <div className="img-item" key={k}>
                                        <ScrollAnimation animateIn="fadeInUp" delay={100*k} animateOnce>
                                            <VideoCard
                                                cardTitle={award.Title}
                                                cardImg={award.Image.url}
                                                searchLink={award.Category}
                                            />
                                        </ScrollAnimation>
                                    </div>
                                )}
                            </Slider>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};
export default Awards;
