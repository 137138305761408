import { Link, navigate } from "gatsby";
import { isEmpty, find } from 'lodash';
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import "./Banner.scss";
import HTMLReactParser from 'html-react-parser';
import Parallax from 'parallax-js';
import SearchInputBox from "../../predictive-search/search-multiareas";
import AreaListBox from "../../SearchResult/FilterBlock/AreaList";
import { createSearchUrlFromParams } from "../../SearchResult/FilterBlock/functions";
import Img from "gatsby-image";

import $ from "jquery";
import { GetURL } from "../../utils";

const HomeBanner = (props) => {
    const [query, setQuery] = React.useState("");
    const [isMobile, setIsMobile] = React.useState(false);
    const settings = {
        dots: false,
        speed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        variableWidth: true,
        centerPadding: "18px",
        autoplay: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 99999,
                settings: "unslick",
                autoplay: true,
            },
        ],
    };

    useEffect(() => {
        var scene = window.document.getElementById('scene');
        if (scene) {
            var parallaxInstance = new Parallax(scene, {
                relativeInput: true,
                scalarX: 23,
                scalarY: 12
            });
            parallaxInstance.friction(0, 0);
            if (window) {
                if (window.innerWidth <= 768) {
                    setIsMobile(true)
                }
            }
        }
        // var scene2 = window.document.getElementById('scene2');
        // var parallaxInstance2 = new Parallax(scene2, {
        //     relativeInput: true
        // });
        // parallaxInstance2.friction(0.2, 0.2);

    }, []);

    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);

    const handleMultiAreaFun = (val) => {
        let areas = [];
        // find
        if (isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear = false) => {
        if (!clear) {
            let prevAreas = areasArr.filter(x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }

    function handleSubmit(e, search_type) {
        e.preventDefault();
        let customValue = [];
        if (areasArr) {
            areasArr.map((a) => {
                if (a) {
                    customValue.push(a.slug)
                }
            })
        }

        let params = {
            areas: customValue,
            search_type: search_type
        }
        navigate(createSearchUrlFromParams(params));
    }


    // let url = "/";
    //
    // const searchBuy = (e) => {
    //     var searcValue = query.split(', ').join('-').replace(/ /g,"-").replace(/---/g,"-").replace(/'/g,"").toLowerCase();
    //
    //     if(searcValue !== "") {
    //         url = "in-"+searcValue+"/"
    //     } else {
    //         url = "in-dubai/"
    //     }
    //
    //     navigate('/properties/for-sale/'+url);
    // }
    //
    // const searchRent = (e) => {
    //     var searcValue = query.split(', ').join('-').replace(/ /g,"-").replace(/---/g,"-").replace(/'/g,"").toLowerCase();
    //
    //     if(searcValue !== "") {
    //         url = "in-"+searcValue+"/"
    //     } else {
    //         url = "in-dubai/"
    //     }
    //
    //     navigate('/properties/for-rent/'+url);
    // }

    return (
        <div className="banner-section banner-section-scene">
            <Container className="h-100">
                <Row className="h-100">
                    <Col lg="12" className="order-lg-1 h-100">
                        <div className="banner-imgslider imgslider-one h-100">
                            <div id="scene" className="d-none d-xl-block">
                                <div data-depth="0.3" className="img-item img-wrp1 img-zoom img-zoom-hover">
                                    <div className="w-100">
                                        <img src={props?.data?.Images[0]?.url} alt={props?.data?.Images[0]?.alternativeText} />
                                        <div class="overlay">
                                            <p>{props?.data?.Banner_Image_Caption[0]?.Caption}</p>
                                        </div>
                                    </div>
                                </div>
                                <div data-depth="0.4" className="img-item img-zoom img-zoom-hover d-md-none d-lg-block">
                                    <div className="w-100">
                                        <img src={props?.data?.Images[1]?.url} alt={props?.data?.Images[1]?.alternativeText} />
                                        <div class="overlay">
                                            <p>{props?.data?.Banner_Image_Caption[1]?.Caption}</p>
                                        </div>
                                    </div>
                                </div>
                                <div data-depth="0.5" className="img-item img-wrp2 img-zoom img-zoom-hover">
                                    <div className="w-100">
                                        <img src={props?.data?.Images[2]?.url} alt={props?.data?.Images[2]?.alternativeText} />
                                        <div class="overlay">
                                            <p>{props?.data?.Banner_Image_Caption[2]?.Caption}</p>
                                        </div>
                                    </div>
                                </div>
                                <div data-depth="0.4" className="img-item img-wrp3 img-zoom img-zoom-hover">
                                    <div className="w-100">
                                        <img src={props?.data?.Images[3]?.url} alt={props?.data?.Images[3]?.alternativeText} />
                                        <div class="overlay">
                                            <p>{props?.data?.Banner_Image_Caption[3]?.Caption}</p>
                                        </div>
                                    </div>
                                </div>
                                <div data-depth="0.3" className="img-item img-wrp4 img-zoom img-zoom-hover">
                                    <div className="w-100">
                                        <img src={props?.data?.Images[4]?.url} alt={props?.data?.Images[4]?.alternativeText} />
                                        <div class="overlay">
                                            <p>{props?.data?.Banner_Image_Caption[4]?.Caption}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-block d-xl-none">
                                <Slider {...settings}>
                                    <div className="img-item img-wrp1 img-zoom">
                                        <div className="w-100">
                                            <Img fluid={props?.data?.Images[0]?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Images[0]?.alternativeText} loading="auto" />                                         
                                        </div>
                                    </div>
                                    <div className="img-item img-zoom d-md-none d-lg-block">
                                        <div className="w-100">
                                            <Img fluid={props?.data?.Images[1]?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Images[1]?.alternativeText} loading="auto" />
                                        </div>
                                    </div>
                                    <div className="img-item img-wrp2 img-zoom">
                                        <div className="w-100">
                                            <Img fluid={props?.data?.Images[2]?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Images[2]?.alternativeText} loading="auto" />
                                        </div>
                                    </div>
                                    <div className="img-item img-wrp3 img-zoom">
                                        <div className="w-100">
                                            <Img fluid={props?.data?.Images[3]?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Images[3]?.alternativeText} loading="auto" />
                                        </div>
                                    </div>
                                    <div className="img-item img-wrp4 img-zoom">
                                        <div className="w-100">
                                            <Img fluid={props?.data?.Images[4]?.url_sharp?.childImageSharp.fluid} alt={props?.data?.Images[4]?.alternativeText} loading="auto" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <Form className="banner-form">
                            <div>
                                <div className="img-item img-wrp1 img-zoom">
                                    {props.data && HTMLReactParser(props.data.HomeBanner_Title)}

                                    <Form.Group controlId="formBasicEmail" className="searcch-box">
                                        <div className="home-searcch-box-wrap">
                                            <SearchInputBox
                                                setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun}
                                            />
                                            <Button variant="primary" type="submit">
                                                <i className="icon-location"></i>
                                            </Button>
                                            <div className="change-type d-flex align-items-center justify-content-between">
                                                <a href={"javascript:;"} onClick={(e) => handleSubmit(e, 'sales')} className="text-uppercase">
                                                    buy
                                                </a>
                                                <a href={"javascript:;"} onClick={(e) => handleSubmit(e, 'lettings')} className="text-uppercase">
                                                    rent
                                                </a>
                                            </div>
                                        </div>
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </Form.Group>
                                    <p className="book-txt">
                                        <span>or</span>{" "}
                                        <Link to={`/${GetURL(props?.data?.Cta_Link?.id)}`} className="text-uppercase">
                                            {props?.data?.Cta_Label}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomeBanner;
