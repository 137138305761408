import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { Container, Nav, Modal, Tab } from "react-bootstrap"
import PropertyCard from "../PropertyCard/PropertyCard"
import "./PropertyTab.scss"
import ScrollAnimation from "react-animate-on-scroll"
import SubscribeHot10Page from "../../forms/subscribe-hot-10"
import $ from "jquery"

const omittedAreas = ["land", "warehouse", "penthouse", "townhouse"]

const PropertyTab = props => {
  const sliderRef = React.createRef(null)
  const [building, setBuilding] = React.useState("")
  const [buildings, setBuildings] = React.useState([])
  const [buildingsArray, setBuildingsArray] = React.useState([])
  const [properties, setProperties] = React.useState([])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const myRefs = React.useRef([])
  myRefs.current = buildings.map(
    (element, i) => myRefs.current[i] ?? React.createRef()
  )

 

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        propertiesConnection(
          where: {
            publish: true
            top_ten: "Yes"
            search_type: "sales"
          }
          limit: 1000
        ) {
          groupBy {
            building {
              key
              count: connection {
                aggregate {
                  count
                }
              }
            }
          }
          values {
            _id
            id
            title
            crm_id
            hot_property
            address
            top_ten
            search_type
            accomadation_summary
            building
            bedroom
            bathroom
            department
            display_address
            exclusive_listing
            floorarea_min
            floorarea_type
            images
            imagetransforms
            introtext
            kitchen
            price
            price_qualifier
            slug
            special
            status
            style
            title
            video_link
            virtual_tour
            room_details
            rent
            reception
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (
      data &&
      data.glstrapi &&
      data.glstrapi.propertiesConnection &&
      data.glstrapi.propertiesConnection.values
    ) {
      let filteredProperties = data.glstrapi.propertiesConnection.values;
      // let filteredProperties = data.glstrapi.propertiesConnection.values;
      let buildingsArr = data.glstrapi.propertiesConnection.groupBy.building.map(
        c => ({ key: c.key, count: c.count.aggregate.count })
      )

      setBuildingsArray(buildingsArr);

      let buildingsList = buildingsArr
        .sort((a, b) => b.count - a.count)
        .map(c => c.key)
        .filter(c => !omittedAreas.includes(c))

      // let buildingsList = [...new Set(data.glstrapi.properties.filter(c => c.building != null).map(c => c.building).flat())];
      setBuildings(buildingsList)
      setBuilding(buildingsList[0])
      setProperties(filteredProperties)
    }
    $("a[href='#']").removeAttr("href")
  }, [])

  const getAbsoluteTop = () => {
    let targetEl = document.getElementsByClassName("bg-gradient-module")[0]
    if (targetEl != null) {
      const targetRect = targetEl.getBoundingClientRect()
      const top = targetRect.height / 2
      if (top != 0) {
        return top
      }
    }
    return 250
  }
  const countCheck = buildingsArray.find(e => e.key === building)

  const settings1 = {
    dots: false,
    speed: 800,
    autoplay:  false,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: countCheck?.count > 6 ? true : false,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    variableWidth: true,
    centerPadding: "18px",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: true,
          autoplay: false,
          slidesToShow: 1,
        },
      },
    ],
  }

const value = properties.map(res => { return res })
const Properties = value.filter(val => { if(val.status !== 'sold') return val })

  return (
    <div className="property-tabsec">
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div>
            <h2 className="main-title">
              {props?.data?.Title.split(" ").map((c, i) => (
                <React.Fragment key={i}>
                  {i === 1 ? <span className="px-2">{c}</span> : c}
                </React.Fragment>
              ))}
            </h2>
            <div className="desc-wrp d-md-flex justify-content-between">
              <p className="desc">{props?.data?.Description}</p>
              <a
                href="javascript:;"
                className="btn subscribe-btn d-none d-xl-inline-block"
                onClick={handleShow}
              >
                <i className="icon-mail"></i>
                {props?.data?.Cta_Label}
              </a>
            </div>
            {buildings.length > 0 && (
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={buildings[0]}
              >
                <div className="navtab-list d-flex aligin-items-center justify-content-between">
                  <strong className="d-inline-block text-uppercase small-title">
                    hot 10:
                  </strong>
                  <Nav variant="pills">
                    {buildings.map((eKey, i) => (
                      <Nav.Item key={i}>
                        <Nav.Link
                          className={building === eKey ? "active" : ""}
                          onClick={() => {
                            setBuilding(eKey)
                          }}
                          eventKey={eKey}
                        >
                          {eKey+"s"}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <Tab.Content>
                  {buildings.map((eKey, k) => {
                    return (
                      <Tab.Pane
                        eventKey={eKey}
                        key={k}
                        className="position-relative"
                      >
                        <Slider
                          {...settings1}
                          ref={myRefs.current[k]}
                          className="banner-imgslider d-block"
                        >
                          {Properties
                            .filter(c => c.building === eKey)
                            .map(
                              (property, i) =>
                                i < 20 && (
                                  // <Link className="img-item" key={i} to={`/properties-${property.search_type === "sales" ? "for-sale" : "for-rent"}/${property.slug}-${property._id}`}>
                                  <div className="img-item" key={property._id}>
                                    {property.images &&
                                      property.images.length > 0 && (
                                        <PropertyCard
                                          cardImg={property?.images[0]?.url}
                                          imagename="property.images.similar"
                                          id={property._id}
                                          attr={{
                                            alt:
                                              property.display_address +
                                              " - Espace",
                                            id: "imgTop" + k + i,
                                          }}
                                          property={property}
                                          cardTitle={property.title}
                                          searchType={property.search_type}
                                          cardPrice={`AED ${property.price.toLocaleString()}`}
                                          cardDesc={property.display_address}
                                          totalBed={`${property.bedroom} Bedrooms`}
                                          totalSize={`${property.floorarea_min} ${property.floorarea_type}.`}
                                        />
                                      )}
                                  </div>
                                )
                            )}
                        </Slider>
                        {properties.filter(c => c.building === eKey).length >
                          2 && (
                          <div className="card-slider">
                            <button
                              type="button"
                              className="slick-arrow slick-prev d-block"
                              onClick={() => {
                                myRefs.current[k].current.slickPrev()
                              }}
                              style={{
                                top: getAbsoluteTop(),
                              }}
                            >
                              {" "}
                              Previous
                            </button>
                            <button
                              type="button"
                              className="slick-arrow slick-next d-block"
                              style={{
                                top: getAbsoluteTop(),
                              }}
                              onClick={() => {
                                console.log(myRefs.current[k])
                                myRefs.current[k].current.slickNext()
                              }}
                            >
                              {" "}
                              Next
                            </button>
                          </div>
                        )}
                      </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Tab.Container>
            )}
          </div>
        </ScrollAnimation>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="contact-form-sec"
      >
        <div className="contact-form subsribe-form">
          <Modal.Header closeButton>
            <Modal.Title>Subscribe to the Top 10</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SubscribeHot10Page />
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

export default PropertyTab
