import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../scss/custom.scss"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Modules from "../components/modules"
import _ from "lodash"

const DefaultTemplate = props => {
  const GQLPage = props.data.glstrapi?.article
  const Page_Modules = props.data.glstrapi?.article?.Modules
  const siteConfig = props.data.glstrapi?.siteConfig
  return (
    <React.Fragment>
      <SEO
        title={GQLPage.Meta_Title}
        description={GQLPage.Meta_Description}
        location={props.location}
      />
      <Modules
        page={GQLPage}
        modules={Page_Modules}
        {..._.omit(props, ["data"])}
        reviews={props?.pageContext?.reviews}
        office_ph={props?.pageContext?.office_ph}
        siteConfig={siteConfig}
      />
    </React.Fragment>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
    glstrapi {
      article(id: $id, publicationState: LIVE) {
        _id
        id
        Title
        Pagename
        Meta_Title
        Meta_Description
        Layout
        Custom_Css_Class
        Alias
        Show_Search
        Show_Review
        Show_Footer_Contact_Form
        Banner_Image {
          alternativeText
          url
        }
        Cta_Video_Link
        Cta_1_Label
        Cta_2_Label
        Cta_1_Link {
          id
          URL
        }
        Cta_2_Link {
          id
          URL
        }
        Embed_Video_Url
        createdAt
        Popular_Search
        Modules {
          ... on GLSTRAPI_ComponentComponentsHomeBanner {
            id
            HomeBanner_Title: Title
            Images {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  fluid(quality: 50, maxWidth: 271, maxHeight: 171) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Cta_Label
            Banner_Image_Caption{
              Caption
            }
            Cta_Link {
              id
              URL
            }
          }
          ... on GLSTRAPI_ComponentComponentsContentWithImages {
            __typename
            id
            Title
            Content
            Images {
              url
              alternativeText
            }
          }
          ... on GLSTRAPI_ComponentComponentsLogosModule {
            __typename
            id
            Title
            Logos {
              Image {
                url
              }
              siteurl
            }
          }
          ... on GLSTRAPI_ComponentComponentsVideoComponent {
            id
            Embed_Url
            Bg_Video {
              url
            }
            Video_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsImageContainer {
            id
            Cta_Label
            Content
            Cta_Link {
              URL
              id
            }
            Background_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1800, maxHeight: 637) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsChooseCollection {
            id
            Title
            Description
            Cta_Label
            Collection_Type
            Cta_Link {
              URL
              _id
            }
          }
          ... on GLSTRAPI_ComponentComponentsAboutContent {
            id
            Title
            Description
            Choose_Right_Content
            Explore_Title
            Explore {
              Label
              Link {
                id
                URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsAboutusContent {
            id
            Tile_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 866, maxHeight: 721) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Embed_Video_Url
            Cta_Link {
              URL
              id
            }
            Cta_Label
            Content
          }
          ... on GLSTRAPI_ComponentComponentsContentWithList {
            __typename
            id
            Title
            Sm_Title
            Sm_Title_Link {
              id
              URL
            }
            Description
            Service_Cards {
              _id
              S_Title
              Description
              Cta_Label
              Cta_Link {
                URL
                id
              }
              Tile_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 866, maxHeight: 721) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsMortgageSection {
            id
            Mortgage_Content
            Bg_Image {
              url
            }
          }
        }
        SM_Title
        Speak_with_Team {
          Tile_Image {
            id
            alternativeText
            url
          }
          Email
          Name
          Phone
          Designation
        }
        updatedAt
        published_at
        imagetransforms
      }
      siteConfig {
        Office_Phone
        Office_Image {
          url
          alternativeText
        }
        Office_Address
        Office_Email
        Office_Whatsapp
      }
    }
  }
`
