import { graphql, Link, useStaticQuery } from "gatsby";
import  React from "react";
import { Card, Container, Row, Col, Accordion} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useContext } from "react";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import "./Faqs.scss";
import { useLocation } from "@reach/router";

const Faqs = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
        const isCurrentEventKey = currentEventKey === eventKey;
        return (
            <button
                type="button"
                className={isCurrentEventKey ? "active card-header position-relative" : "card-header position-relative"}
                onClick={decoratedOnClick}
            >
                {children}
                <i className={isCurrentEventKey ? "icon-caretdown up" : "icon-caretdown"}></i>
            </button>
        );
    }
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                faqs(sort: "Sort", where: {Publish: true}) {
                    id
                    Title
                    Description
                    Questions_and_Answers {
                        id
                        Title
                        Sort
                        Description
                    }
                }
            }
        }
    `)
    const [category, setCategory] = React.useState("all");
    const { faqs } = data.glstrapi;

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + props.url,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    const location = useLocation();

    const filteredFaqs = category === "all" ? faqs : faqs.filter(f => f.Title === category);
    return (
        <div className="faqs-sec">
            <Container>
                <Row>
                    <Col xl={8}>
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                            <div className="stories-head">
                                <h2 className="main-title">{props.data?.SM_Title}</h2>
                                <p className="head-desc">{props.data?.Title}</p>
                            </div>
                            <ul className="tag-list">
                                <li>
                                    <a  onClick={() => setCategory("all")} className={`text-uppercase cursor-pointer ${category === "all" ? "active" : ""}`}>
                                    all faqs
                                    </a>
                                </li>
                                {faqs.map((each, i) => 
                                    <li key={i}>
                                        <a onClick={() => setCategory(each.Title)} className={`text-uppercase cursor-pointer ${category === each.Title ? "active" : ""}`}>
                                            {each.Title}
                                        </a>
                                    </li>
                                )}
                            </ul>
                            {filteredFaqs.map((each, i) => 
                                <div key={i} className="mb-5">
                                    <h3 className="subtitle">{each.Title}</h3>
                                    <p className="sub-desc">
                                        {each.Description}
                                    </p>
                                    <div className="menu-accordian-boxes">
                                        {each.Questions_and_Answers.map((ques,k) => 
                                            <Accordion className="menu-accordian">
                                                <Card>
                                                    <div>
                                                        <ContextAwareToggle
                                                            eventKey={`${k}`}
                                                            className="accordion-header"
                                                        >
                                                            {ques.Title}
                                                        </ContextAwareToggle>
                                                    </div>

                                                    <Accordion.Collapse eventKey={`${k}`}>
                                                        <div className="accordion-card accordion-card-first card-body">
                                                            <p>{ques.Description}</p>
                                                        </div>
                                                </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        )}
                                    </div>
                                </div>
                            )}
                        </ScrollAnimation>
                    </Col>
                    <Col xl={4} className="d-none d-xl-flex justify-content-end">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce>
                            <div className="faq-profile d-flex flex-column align-items-center">
                                <div className="position-relative text-center">
                                    <img src={props.data?.Speak_with_Team?.Tile_Image.url} className="w-50"/>
                                </div>
                                <div className="social-list">
                                    <h6 className="social-title">Share:</h6>
                                        <div className="social-links">
                                            <a href="javascript:;">
                                                <TwitterShareButton url={location.href} onClick={() => trackerShare("TwitterShareButton")}>
                                                    <i className="icon-twitter-dark"></i>
                                                </TwitterShareButton>
                                            </a>
                                            <a href="javascript:;">
                                                <FacebookShareButton url={location.href} onClick={() => trackerShare("FacebookShareButton")}>
                                                    <i className="icon-facebook-dark"></i>
                                                </FacebookShareButton>
                                            </a>
                                            <a href="javascript:;">
                                                <LinkedinShareButton url={location.href} onClick={() => trackerShare("LinkedinShareButton")}>
                                                    <i className="icon-linkedin-dark"></i>
                                                </LinkedinShareButton>
                                            </a>
                                            <a href="javascript:;" onClick={() => navigator.clipboard.writeText(location.href)}><i className="icon-url-dark"></i></a>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Faqs;
