import { Link } from "gatsby";
import * as React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import "./KnowledgeCenter.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { GetURL } from "../utils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const icons = [
    "icon-phone-red",
    "icon-question-red",
    "icon-book-red",
    "icon-globe-red",
    "icon-pennib-red",
]

const KnowledgeCenter = (props) => {
    const { data, modules } = props;
    let processedImages = JSON.stringify({});
    if (data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = data?.imagetransforms?.Banner_Image_Transforms;
    }
    return (
        <div className="knowledge-center knowledge-center-title">
            <Container>
                <Row>
                    <Col xl={6} xs={12}>
                        <Form className="">
                            <div className="form-head">
                                <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" delay={100} animateOnce>
                                    <h1 className="main-title">{data?.SM_Title}</h1>
                                    <p className="desc">
                                        {data?.Title}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                {/* <ScrollAnimation animateIn="slideInLeft" animateOnce>
                                    <Form.Label>Search Knowledge Center</Form.Label>
                                    <div className="search-fld">
                                    <Form.Control type="text" placeholder="Search term..." />
                                    <button className="search-btn">
                                        <i className="icon-search-red"></i>
                                    </button>
                                    </div>
                                </ScrollAnimation> */}
                            </Form.Group>
                            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" delay={200} animateOnce>
                                <ul className="knowledge-list">
                                    {modules?.[0]?.Service_Cards.map((each, i) => 
                                        <div key={i} className="w-100">
                                            <Link to={`/${GetURL(each.Cta_Link.id)}`} className="w-100">
                                                <li className="d-flex align-items-center justify-content-between">
                                                        <i className={icons[i]}/>
                                                        <i className="icon-long-arrow"/>
                                                        <div className="info">
                                                            <strong className="d-block">{each.S_Title}</strong>
                                                            <span className="d-block">{each.Description}</span>
                                                        </div>
                                                </li>
                                            </Link>
                                        </div>
                                    )}
                                </ul>
                            </ScrollAnimation>
                        </Form>
                    </Col>
                    <Col xl={6} xs={12}>
                        <div className="knowledge-banner d-none d-xl-block">
                            <ImageTransform
                                imagesources={data?.Banner_Image?.url}
                                renderer="srcSet" imagename="articles.Banner_Image.contact"
                                attr={{ alt: data?.Banner_Image?.alternativeText, class: '' }}
                                imagetransformresult={processedImages}
                                className="bg-img"
                                id={data?.id}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default KnowledgeCenter;
