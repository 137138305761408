import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles/index.scss'
import HTMLReactParser from "html-react-parser";
import MortgageCalculator from './MortgageCalculator';
import { GetURL, removePlus } from '../utils';
import { Link } from 'gatsby';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useLocation } from '@reach/router';


const MortgageSection = (props) => {
    const [showLink, setShowLink] = useState(false);

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + props.url,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    var officePhone = props?.siteConfig?.Office_Phone?.split(" ").join("")
    const location = useLocation();

  return (
    <div className='mortgage-section' id='mortgage-calculator-section'>
        <Container>
            <Row className='mortgage-section-inner'>
                {/* <Col lg={6} md={12}>
                    <div className='left-content'>
                        <h1 className="main-title">{props?.data?.SM_Title}</h1>
                        <p>{props.data.Title}</p>
                        {HTMLReactParser(props.modules[0].Content)}
                        <div className='contact-section'>
                            <a
                                href={`https://api.whatsapp.com/send?phone=${removePlus(props?.siteConfig?.Office_Whatsapp)}`} 
                                target="_blank" 
                                className="btn subscribe-btn speak-btn"><i class="icon-whatsapp"></i>Whatsapp us
                            </a>
                            {props?.siteConfig?.Office_Phone && 
                                <Link
                                    href={`tel: ${officePhone}`}
                                    className="btn subscribe-btn speak-btn call-btn"
                                ><i class="icon-mobile"></i>Call</Link>
                            }
                            {props?.data?.Cta_1_Label && 
                                <Link to={`/${GetURL(props?.data?.Cta_1_Link?.id)}`} className="btn subscribe-btn speak-btn">{props?.data?.Cta_1_Label}</Link>
                            }
                        </div>
                        <div className='social-section position-relative'>
                            <h4 className="social-title">Share:</h4>
                                <a href="javascript:;">
                                    <TwitterShareButton url={location.href} onClick={() => trackerShare("TwitterShareButton")}>
                                        <i className="icon-twitter-dark"></i>
                                    </TwitterShareButton>
                                </a>
                                <a href="javascript:;">
                                    <FacebookShareButton url={location.href} onClick={() => trackerShare("FacebookShareButton")}>
                                        <i className="icon-facebook-dark"></i>
                                    </FacebookShareButton>
                                </a>
                                <a href="javascript:;">
                                    <LinkedinShareButton url={location.href} onClick={() => trackerShare("LinkedinShareButton")}>
                                        <i className="icon-linkedin-dark"></i>
                                    </LinkedinShareButton>
                                </a>
                                <a href="javascript:;" 
                                    title="Copy Link" 
                                    onClick={() => {
                                        navigator.clipboard.writeText(location.href);
                                        setShowLink(true);
                                        setTimeout(() => {
                                            setShowLink(false)
                                        }, 1000);
                                    }} 
                                    className="position-relative"
                                >
                                    <i className="icon-url-dark"></i>
                                </a>
                                {showLink && <div className="alert alert-light link-alert">Link copied</div>}
                        </div>
                    </div>
                </Col> */}
                <Col lg={6} md={12}>
                    <MortgageCalculator section={true}/>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MortgageSection