import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Careers.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";

const Careers = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                careers(sort: "Sort", where: {Publish: true}) {
                    Title
                    Sub_Title
                    URL
                }
            }
        }
    `);
    return (
        <div className="careers-list" id="careers-list-ct">
            <Container>
                <Row>
                    <Col md={12}>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce>
                            <div className="stories-head">
                                <h2 className="main-title">{props?.data?.Title}</h2>
                            </div>
                            <ul className="careers-list-ul d-md-flex flex-wrap">
                                {data.glstrapi.careers.map((each, i) => 
                                    <li key={i}>
                                        <Link to={`/real-estate-job-vacancies/${each.URL}/`} className="w-100 h-100">
                                            <strong className="d-block">{each.Title}</strong>
                                            <span className="d-block">{each.Sub_Title}</span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Careers;
