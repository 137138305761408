import * as React from 'react';
import {Link} from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import Slider from 'react-slick';
import { GetURL, removePlus } from "../utils";
import MortgageCalculator from './MortgageCalculator';
import SocialList from '../StaticBanner/social-list';
import '../StaticBanner/StaticBanner.scss';

const MortgageServices = (props) => {
        
   const officePhone = props?.siteConfig?.Office_Phone?.split(" ").join("");

  return (
    <div className="static-detail-sec mortgage-services-page">
        <React.Fragment>
            <Container>
                <Row>                 
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <Row>
                            <Col md = {12}>
                                <h1 className="main-title hidden">{props?.data?.SM_Title}</h1>
                            </Col>
                            <Col lg = {6}>                               
                                <div className="news-info d-xl-flex justify-content-between">
                                    {props.modules &&
                                        <div className='info w-100'>
                                            {props?.modules.filter(c => c.__typename ==="GLSTRAPI_ComponentComponentsContentWithImages" && c.Content ).map ((item, index) =>
                                                <React.Fragment key={item.id}>
                                                    {item.Title && <h3 className="para-title">{item.Title}</h3>}
                                                    <p>{HTMLReactParser(item.Content)}</p>
                                                    { typeof item.Images !== "undefined" && item.Images.length > 0 &&
                                                        <Slider slidesToScroll={1} slidesToShow={1} infinite={true} arrows={true} className="card-slider d-block h-auto">
                                                            {item.Images.map((img, k) => 
                                                                <div className="img-gallery" key={k}>
                                                                    <div className="main-img img-zoom">
                                                                        <img src={img.url} alt="" className="info-img-list"/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Slider>
                                                    }
                                                </React.Fragment>                                               
                                            )}
                                        </div>
                                    } 
                                </div>
                                <div className="contact-details-section">
                                    <ul className="d-flex">
                                        <li>
                                            <a href={`https://api.whatsapp.com/send?phone=${removePlus(props?.siteConfig?.Office_Whatsapp)}`} target="_blank" rel="noreferrer" className="btn subscribe-btn speak-btn"><i class="icon-whatsapp"></i> Whatsapp us</a>
                                        </li>
                                        <li>
                                            {props?.siteConfig?.Office_Phone && 
                                               <Link
                                               href={`tel: ${officePhone}`}
                                               className="btn subscribe-btn speak-btn call-btn"
                                           ><i class="icon-mobile"></i> Call</Link>
                                            }
                                        </li>
                                       
                                        <li>
                                            {props?.data?.Cta_1_Label && 
                                                <Link to={`/${GetURL(props?.data?.Cta_1_Link?.id)}`} className="btn subscribe-btn speak-btn">{props?.data?.Cta_1_Label}</Link>
                                            }
                                        </li>
                                    </ul>                                   
                                </div>
                                <div className="social-details">
                                    <SocialList />
                                </div>
                            </Col>
                            <Col lg = {6}>
                                <div className="mortgage-calculator-wrapper">
                                    <MortgageCalculator team ={props.data.Speak_with_Team} />
                                </div>
                            </Col>
                        </Row> 
                    </ScrollAnimation>
                </Row>
            </Container>
        </React.Fragment>
    </div>
   
  )
};
export default MortgageServices;