import React, { useEffect } from "react"
import ApplicationForm from "./AllModules/ApplicationForm/ApplicationForm"
import AreaGuides from "./AllModules/AreaGuides/AreaGuides"
import Awards from "./AllModules/Awards/Awards"
import Calculators from "./AllModules/Calculators/Calculators"
import Careers from "./AllModules/Careers/Careers"
import ContactForm from "./AllModules/ContactForm/ContactForm"
import CustomerStories from "./AllModules/CustomerStories/CustomerStories"
import Featured from "./AllModules/Featured"
import Faqs from "./AllModules/Faqs/Faqs"
import IndustryLeader from "./AllModules/IndustryLeader/IndustryLeader"
import BlogArticles from "./AllModules/Insights/blog-articles"
import Insights from "./AllModules/Insights/Insights"
import ModuleBanner from "./AllModules/ModuleBanner/ModuleBanner"
import News from "./AllModules/News/News"
import Reviews from "./AllModules/Reviews/Reviews"
import Teams from "./AllModules/Teams/Teams"
import Videos from "./AllModules/Videos/videos"
import ElfsightYoutubeWidget from "./AllModules/ElfsightWidgets/ElfsightYoutubeWidget"
import VideoSuggestions from "./AllModules/VideoSuggestions/VideoSuggestions"
import Contact from "./Contact/Contact"
import ContactOrValuationForm from "./ContactForm/ContactForm"
import FeaturedProperties from "./FeaturedProperties/FeaturedProperty"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import AboutMarket from "./Home/AboutMarket/AboutMarket"
import ElfsightVideos from "./Home/ElfsightVideos/ElfsightVideos"
import AgencyService from "./Home/AgencyService/AgencyService"
import AgentBanner from "./Home/AgentBanner/AgentBanner"
import HomeBanner from "./Home/Banner/Banner"
import PropertyTab from "./Home/PropertyTab/PropertyTab"
import UserRating from "./Home/UserRating/UserRating"
import VideoBanner from "./Home/VideoBanner/VideoBanner"
import KnowledgeCenter from "./KnowledgeCenter/KnowledgeCenter"
import StaticBanner from "./StaticBanner/StaticBanner"
import MortgageServices from "./MortgageSection/MortgageServices"
import ContentList from "./ContentList/ContentList"
import handleViewPort from "react-in-viewport"
import MortgageSection from "./MortgageSection/MortgageSection"

const ReviewBlk = props => {
  const { inViewport, forwardedRef, enterCount } = props
  return (
    <div ref={forwardedRef}>
      {(inViewport || enterCount > 0) && <UserRating {...props} />}
    </div>
  )
}

const UserRatingBlock = handleViewPort(ReviewBlk, {
  config: { disconnectOnLeave: true },
})

const Hot10PropertiesBlk = props => {
  const { inViewport, forwardedRef, enterCount } = props
  return (
    <div ref={forwardedRef}>
      {(inViewport || enterCount > 0) && <PropertyTab {...props} />}
    </div>
  )
}

const Hot10Properties = handleViewPort(Hot10PropertiesBlk, {
  config: { disconnectOnLeave: true },
})

const Modules = props => {
  // useEffect(() => {
  //     if (document.getElementById("bugherd") === null) {
  //         var bugherdScript = document.createElement('script');
  //         bugherdScript.id = 'bugherd';
  //         bugherdScript.async = true;
  //         bugherdScript.type = 'text/javascript';
  //         bugherdScript.src = 'https://www.bugherd.com/sidebarv2.js?apikey=sxzx6vxjsyjxfzbbbtqyqw';
  //         document.getElementsByTagName('body')[0].appendChild(bugherdScript);
  //     }
  // }, [])
    const toMail = ('production' === process.env.MAIL_ENV ? process.env.MAIL_TO : process.env.MAIL_BCC);
    const ccMail = ('production' === process.env.MAIL_ENV ? "jeric@esapce.ae" : process.env.MAIL_BCC);
  return (
    <div className={props.page.Custom_Css_Class}>
      <Header
        headerTwo={
          props.page.Layout === "Default" || props.page.Layout === "Contact"
        }
      />
      {props.page.Layout === "Default" && (
        <ModuleBanner
          data={props.page}
          reviews={props?.reviews}
          office_ph={props?.office_ph}
        />
      )}
      {props.page.Layout === "No_Banner" &&
        props.page.Pagename === "Insights" && (
          <Insights showHeader={true} page={props.page} />
        )}
      {props.page.Layout === "No_Banner" &&
        props.page.Pagename === "Blog Articles" && (
          <BlogArticles showHeader={true} page={props.page} />
        )}
      {props.page.Layout === "No_Banner" &&
        props.page.Pagename === "Videos" && (
          <ElfsightYoutubeWidget showHeader={true} path={props.path} WidgetId="fec30bb3-ff0b-425a-9d0d-89e1bc0a7860" />
        )}
      {props.page.Layout === "No_Banner" && props.page.Pagename === "FAQs" && (
        <Faqs showHeader={true} data={props.page} />
      )}
      {props.page.Layout ==="No_Banner" && props.page.Pagename === "Mortgage Services" && (
        <MortgageServices modules={props.modules}
        data={props.page}
        siteConfig={props.siteConfig} />
      )}
      {props.page.Layout === "No_Banner" &&
        !["Insights", "Videos", "FAQs", "Blog Articles","Mortgage Services"].includes(
          props.page.Pagename
        ) && (
          <StaticBanner
            modules={props.modules}
            data={props.page}
            siteConfig={props.siteConfig}
          />
        )}
      {props.page.Layout === "Split_View" &&
        props.page.Pagename === "Knowledge Centre" && (
          <KnowledgeCenter data={props.page} modules={props.modules} />
        )}
      {props.page.Layout === "Split_View" &&
        props.page.Pagename === "Contact" && (
          <Contact
            data={props.page}
            modules={props.modules}
            reviews={props?.reviews}
          />
        )}
      {props.page.Layout === "Contact" && (
        <ContactOrValuationForm data={props.page} reviews={props?.reviews} modules={props.modules}/>
      )}
      {props.modules.map((module, i) => (
        <React.Fragment key={i}>
          {module.HomeBanner_Title && props.page.Layout === "Homepage" && (
            <HomeBanner data={module} />
          )}
          {module.Choose_Right_Content && (
            <IndustryLeader data={module} pageName={props.page.Pagename} />
          )}

          {module.Video_Image && module.Embed_Url && (
            <VideoBanner
              image={module.Video_Image}
              video={module.Embed_Url}
              Bg_Video={module.Bg_Video}
            />
          )}
          {module.__typename === "GLSTRAPI_ComponentComponentsLogosModule" &&
            <Featured data={module} />
          }
          {module.Collection_Type === "Homepage_Top_10" && (
            <Hot10Properties data={module} />
          )}
          {module.Collection_Type === "Customer_Stories" && (
            <CustomerStories data={module} />
          )}
          {module.Collection_Type === "Area_Guides" && (
            <AreaGuides data={module} path={props.path} />
          )}
          {module.Collection_Type === "Calculators" && (
            <Calculators data={module} path={props.path} />
          )}
          {module.Collection_Type === "Reviews" && (
            <Reviews reviews={props?.reviews} />
          )}
          {module.Collection_Type === "Elfsight" && (
            <ElfsightVideos data={module} />
          )}
          {module.Collection_Type === "Featured_Properties" && (
            <FeaturedProperties page={props.page} data={module} />
          )}
          {module.Collection_Type === "Featured_Properties_Sales" && (
            <FeaturedProperties page={props.page} query="sales" data={module} />
          )}
          {module.Collection_Type === "Featured_Properties_Lettings" && (
            <FeaturedProperties
              page={props.page}
              query="lettings"
              data={module}
            />
          )}
          {module.Collection_Type === "Careers" && <Careers data={module} />}
          {module.Collection_Type === "Videos" && (
            // <VideoSuggestions data={module} />
            <ElfsightYoutubeWidget showHeader={true} path={props.path} WidgetId="fec30bb3-ff0b-425a-9d0d-89e1bc0a7860" />
          )}
          {module.Collection_Type === "Awards" && <Awards data={module} />}
          {module.Collection_Type === "Meet_Our_Team" && (
            <Teams data={module} page={props.page} />
          )}
          {module.Background_Image && <AgentBanner data={module} />}
          {module.Content && module.Tile_Image && <AboutMarket data={module} />}
          {module.Service_Cards &&
            !["Knowledge Centre", "Contact", "Property Valuation"].includes(props.page.Pagename) && (
              <AgencyService data={module} />
            )}
          {props.page.Pagename === "Mortgage" &&
            <MortgageSection Module={module} />
          }
        </React.Fragment>
      ))}
      {(props.page.Layout === "Homepage" || (props.page.Layout === "Contact" && props.page.Pagename === "Property Valuation")) && (
        <UserRatingBlock reviews={props?.reviews} pageName={props?.page?.Pagename} />
      )}
      {props.page.Show_Footer_Contact_Form && (
        <ContactForm
          career={props.page.Pagename === "Careers"}
          {...(props.page.Pagename === "Careers" && {
            page_type: "career-landing",
            key: "form-2",
            form_key: "career-form-2",
            career: true,
            formname: "SEND US CV",
          })}
          holiday={props.page.Pagename === "Holiday Homes"}
          {...(props.page.Pagename === "Holiday Homes" && {
            page_type: "holiday-homes",
            email: toMail,
            ccmail: ccMail
          })}
          formname="BookACall"
        />
      )}
      <Footer popularSearch={props.page.Popular_Search} />
    </div>
  )
}

export default Modules
